/**
 * Const with disabled spinner endpoints.
 * Add new endpoints to this array for hide spinner on that endpoint.
 */
export const DISABLED_SPINNER_ENDPOINTS = [
 'validate',
 'timeout',
 'uploadFile',
 'call',
 'ping',
];
