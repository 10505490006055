import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from './services/locale.service';

@Pipe({
  name: 'locale',
  pure: false
})
export class LocalePipe implements PipeTransform {

  constructor(private localization:LocaleService) {}

  transform(key: string) {
    return this.localization.translations[key] || key;;
  }

}
