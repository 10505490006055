import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StickerService } from 'src/app/services/sticker.service';

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.css']
})
export class StickerComponent implements OnChanges {
  @Input() title: string | null = '';
  @Input() description!: string | null;

  constructor(private stickerService: StickerService) {
    this.stickerService.title$.subscribe(title => this.title = title);
    this.stickerService.description$.subscribe(description => this.description = description);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) {
      this.stickerService.title = changes.title.currentValue;
    }

    this.stickerService.description = changes.description?.currentValue;

  }

}
