import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileUploadDirective } from 'src/app/directives/file-upload/file-upload.directive';
import { LocalePipe } from 'src/app/locale.pipe';

@Component({
  selector: 'app-call-options',
  templateUrl: './call-options.component.html',
  styleUrls: ['./call-options.component.css'],
  providers: [LocalePipe],
})
export class CallOptionsComponent {
  @Input() floatingButton = false;
  @Input() videocamera = true;
  @Input() microphone = true;
  @Output() hangout = new EventEmitter<void>();
  @Output() microphoneChange = new EventEmitter<void>();
  @Output() videocameraChange = new EventEmitter<void>();
  @Output() fileToUpload = new EventEmitter<File>();
  @ViewChild(FileUploadDirective)
  fileUploadElement!: FileUploadDirective;

  toggleVideoCamera() {
    this.videocamera = !this.videocamera;
    this.videocameraChange.emit();
  }

  toggleMicrophone() {
    this.microphone = !this.microphone;
    this.microphoneChange.emit();
  }

  clearInputFile(): void {
    this.fileUploadElement.clearInputFile();
  }
}
