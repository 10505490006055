import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Doctor } from 'src/app/models/doctor.model';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CallService } from 'src/app/services/call.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';

const CHECK_DOCTOR_STATUS_INTERVAL = 20000;

@Component({
  selector: 'app-doctor-detail',
  templateUrl: './doctor-detail.component.html',
  styleUrls: ['./doctor-detail.component.css']
})
export class DoctorDetailComponent implements OnInit {

  me: Patient | undefined
  doctor: Doctor | undefined
  isFavorite: boolean = false;
  inAppointment: boolean = false;
  checkDoctorStatusInterval: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private callService: CallService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private amplitudeService: AmplitudeService,
    private appointmentService: AppointmentService,
    private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      if (params.id) {
        this.me = await this.patientService.getMe()
        this.doctor = await this.doctorService.getDoctor(params.id);
        let favouritedDoctors = await this.patientService.getFavouritedDoctors();
        this.isFavorite = favouritedDoctors.some((favouritedDoctor: { id: any; }) => this.doctor ? this.doctor.id == favouritedDoctor.id : false)
        this.inAppointment = this.appointmentService.inAppointment();
        if(this.inAppointment){
          this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_DOCTOR_DETAIL_IN_APPOINTMENT,
            { nombre: this.doctor?.firstName, apellido: this.doctor?.lastName, doctorId: this.doctor?.id })
          this.navigationService.hideMenu();
          this.navigationService.hideOptions();
        } else {
          this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_SELECT_DOCTOR,
            { nombre: this.doctor?.firstName, apellido: this.doctor?.lastName, doctorId: this.doctor?.id })
        }
        this.checkDoctorStatusInterval = setInterval( async () => {
          this.doctor = await this.doctorService.getDoctor(params.id);
        }, CHECK_DOCTOR_STATUS_INTERVAL );
      }
    })
  }

  ngOnDestroy() : void {
    clearInterval(this.checkDoctorStatusInterval);
  }

  async call() {
    if (this.doctor) {
      this.callService.doctor = this.doctor

      if (await this.patientService.hasFamilyGroup())
        this.router.navigate([AppPaths.NAVBAR,AppPaths.FAMILY_GROUP]);
      else {
        if (this.me) this.callService.caller = this.me;
        this.router.navigate([AppPaths.NAVBAR,AppPaths.SELECT_REASON]);
      }
    }
  }

  async addFavoriteDoctor(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.doctor) {
      this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_FAV_DOCTOR, {
        doctor: `${this.doctor.firstName} ${this.doctor.lastName}`,
        doctorId: this.doctor.id
      })
      this.isFavorite = !this.isFavorite;
      await this.patientService.addDoctorToFavourites(this.doctor.id);
    }
  }

  async removeFavoriteDoctor(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.doctor) {
      this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_REMOVE_FAV_DOCTOR, {
        doctor: `${this.doctor.firstName} ${this.doctor.lastName}`,
        doctorId: this.doctor.id
      })
      this.isFavorite = !this.isFavorite;
      await this.patientService.removeDoctorFromFavourites(this.doctor.id);
    }
  }
}
