import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingSpinnerService } from '../services/loading-spinner.service';
import { DISABLED_SPINNER_PATHS } from '../tokens/disabled-spinner-paths.token';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(
    @Inject(DISABLED_SPINNER_PATHS) private disabledSpinnerPaths: string[],
    private spinnerService: LoadingSpinnerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const endpoint = request.url.split('/').slice().pop();

    if (this.disabledSpinnerPaths.includes(endpoint!) || !request.url.includes(environment.API_URL)) {
      return next.handle(request);
    }

    this.spinnerService.toggleSpinner();
    return next.handle(request).pipe(
      finalize(() => {
        this.spinnerService.toggleSpinner();
      })
    );
  }
}
