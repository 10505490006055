<div class="header-background">
  <button backButton mat-icon-button *ngIf="navigationService.backButton$ | async">
    <mat-icon>west</mat-icon>
  </button>
  <div class="logo" [ngClass]="{'header': provider.header}" *ngIf="provider">
    <img *ngIf="provider.header; else ladLogo" [src]="provider.header">
    <ng-template #ladLogo>
      <app-logo [providerTitle]="providerTitle"></app-logo>
    </ng-template>
  </div>

  <div class="options" *ngIf="navigationService.options$ | async">
    <a mat-icon-button [href]="faqURL" target="_blank">
      <mat-icon svgIcon="questions"></mat-icon>
    </a>
    <button *ngIf="showSetting" (click)="showEvent()" mat-icon-button routerLink="{{'/'+settingsPath}}"
      routerLinkActive="active">
      <mat-icon svgIcon="settings"></mat-icon>
    </button>
  </div>
</div>
