import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../../dialog/dialog.component';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { nanoid } from 'nanoid';
import { CapacitorService } from 'src/app/services/capacitor.service';

@Component({
  selector: 'app-auth0-social-login',
  templateUrl: './auth0-social-login.component.html',
  styleUrls: ['./auth0-social-login.component.css']
})
export class Auth0SocialLoginComponent implements OnInit, OnDestroy {
  auth0:any;
  registrationPath = AppPaths.REGISTRATION
  redirectUriCapacitor: string;
  appUrlOpenEventCapacitor!: PluginListenerHandle;
  psConnection: string = environment.PS_AUTH0_CONNECTION

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public amplitudeService: AmplitudeService,
    private patientService: PatientService,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private capacitorService: CapacitorService,
    ) {
      this.redirectUriCapacitor = this.capacitorService.getRedirectUri();
    }

  async ngOnInit(): Promise<void> {
    this.route.fragment.subscribe(async fragment => {
      var params = this.parseParams(fragment);
      if (params.id_token) {
        this.handleLogin(params.id_token);
      } else if (params.error) {
        this.showLoginErrorDialog()
      }
    })

    if(Capacitor.isNativePlatform()) {
      this.appUrlOpenEventCapacitor = await CapacitorApp.addListener('appUrlOpen', ({ url }) => {
        this.ngZone.run(async () => {
          if (url?.startsWith(this.redirectUriCapacitor)) {
            const id_token = url?.split('=')[1].split('&')[0];
            if (id_token) {
              await this.handleLogin(id_token);
              Browser.close();
            } else {
              Browser.close();
              this.showLoginErrorDialog()
            }
          } else {
            Browser.close();
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    if(Capacitor.isNativePlatform()) {
      this.appUrlOpenEventCapacitor.remove();
    }
  }

  async handleLogin(id_token: string): Promise<void> {
    var validAuthentication = this.authService.validateAuthentication(id_token)
      if (validAuthentication) {
        try {
          await this.authService.handleAuth0Login(id_token);
          this.patientService.getMe().then((me: Patient) => {
            this.amplitudeService.setUser(me.id);
            this.amplitudeService.showEvent(AmplitudeEvents.LOGIN_AUTH0,{});
          });
          this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
        } catch(err:any) {
          if (err.status == 409){
            this.amplitudeService.showEvent(AmplitudeEvents.USER_NOT_ACTIVE,{});
            this.router.navigate([AppPaths.CREDENTIAL_VALIDATION])
          } else {
            localStorage.clear();
            this.showNoUserDialog()
          }
        }
      } else {
        //show dialog or just avoid? (probably an old id_token)
      }
  }

  parseParams(fragment: string): any {
    if(!fragment) return {};
    var parts = fragment.split('&');
    var params: any = {};
    parts.forEach(part => {
      const pieces = part.split('=');
      params[pieces[0]] = pieces[1];
    });
    return params;
  }

  redirectToSocialLogin(connection:string){
    if (Capacitor.isNativePlatform()) {
      const url = this.auth0.client.buildAuthorizeUrl({
        redirectUri: this.redirectUriCapacitor,
        connection: connection,
        responseType: 'id_token',
        nonce: nanoid(16)
      })
      Browser.open({ url, windowName: '_self'})
    } else {
      this.auth0.authorize({
        redirectUri: environment.PWA_URL + '/social',
        connection: connection,
        responseType: 'id_token'
      });
    }
  }

  redirectToMailAndPassword(){
    this.router.navigate([AppPaths.SOCIAL, 'email'])
  }

  showNoUserDialog(){
    const dialogRef = this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_login_error_no_user",
        title: "dialog_title_warning",
        canCancel: true
      }
    })

    dialogRef.beforeClosed().subscribe(() => {
      if(Capacitor.isNativePlatform()) {
        this.authService.logoutBrowserCapacitor(true);
      }
    });
  }

  showLoginErrorDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_login_error_custom",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

}
