<div class="mat-dialog-header">
  <h1 mat-dialog-title>
    {{ 'schedules_title' | locale }}
  </h1>
  <mat-icon>
    info
  </mat-icon>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="(specialtyId | specialtyAvailableHours | async) as availableHours">
    <p>
      <ng-container *ngIf="availableHours.length >= 1">
        {{ 'schedules_message_many' | locale }}
      </ng-container>
    </p>
    <ng-template #uniqueHour>
      {{ 'schedules_message_single' | locale }}
    </ng-template>
    <app-schedules [schedules]="availableHours">
    </app-schedules>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button
    color="primary"
    mat-dialog-close
    mat-stroked-button>
    {{ 'dialog_close' | locale }}
  </button>
</div>
