export const environment = {
  production: true,
  ENVIRONMENT: 'develop',
  AUTH0_DOMAIN: 'lad-dev.auth0.com',//"lad-dev.auth0.com",
  AUTH0_CLIENTID: 'zMLFMDUNHqgRpCQLIAmJhnNr3YtveRNw',//"zMLFMDUNHqgRpCQLIAmJhnNr3YtveRNw",
  AUTH0_REALM: 'Affiliates-Username-Password-Authentication',// 'Affiliates-Username-Password-Authentication',
  AUTH0_SCOPE: 'openid offline_access', //'openid offline_access',
  API_URL: 'https://dev.v2.api.llamandoaldoctor.com', // ONLY FOR DEV PURPOSES - TODO REPLACE WITH BB VARS
  PWA_URL: 'https://dev.v2.app.llamandoaldoctor.com', // ONLY FOR DEV PURPOSES - TODO REPLACE WITH BB VARS
  BASE_WS_URL: 'wss://dev.api.llamandoaldoctor.com:8084', // wss://dev.api.llamandoaldoctor.com:8084/
  BITBUCKET_BUILD_NUMBER: "375",
  OPENTOK_API_KEY: '45772292', // 45772292
  callConfig: {
    DEFAULT_CALL_TIMEOUT_MS: 420000, //7 minutos
    MIN_MS_FOR_VALID_CALL: 180000, //3 minutos
  },
  AMPLITUDE_API_KEY: '6f376593d1b466625de64efda5a4f5ca',
  AMPLITUDE_SECRET_KEY: '101455a219651fe0422a7b44270d1847',
  FAQ_URL: "https://llamandoaldoctor.com/paciente.html#faqs-paciente",
  LAD_CONTACT_EMAIL: "contacto@llamandoaldoctor.com", // contacto@llamandoaldoctor.com
  ANALYTICS_ID: "UA-88686723-3",
  PS_AUTH0_CONNECTION: "ps-sancor-dev",
  IOS_APP_STORE: "itms-apps://itunes.apple.com/in/app/llamando-al-doctor/id1196903602",
  ANDROID_APP_STORE: "market://details?id=com.llamandoaldoctor",
};
