import { Pipe, PipeTransform } from '@angular/core';
import { objectToSchedules } from '../functions/object-to-schedules.function';
import { Schedule } from '../models/schedule.model';

@Pipe({
  name: 'doctorWorkingHours'
})
export class DoctorWorkingHoursPipe implements PipeTransform {

  transform(workingHours: { [key: string]: number[] }): Schedule[] {
    if (Object.keys(workingHours || {}).length > 0) {
      return objectToSchedules(workingHours);
    };
    return [];
  }

}
