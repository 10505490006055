import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-family-group',
  templateUrl: './family-group.component.html',
  styleUrls: ['./family-group.component.css'],
})
export class FamilyGroupComponent implements OnInit {
  familyGroupMembers: Patient[] = [];
  patient!: Patient;
  steps!: number;
  isShortFlow: boolean;

  constructor(
    private router: Router,
    private callService: CallService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isShortFlow = this.patientService.hasShortFlow();

    if (!this.isShortFlow && !callService.specialty && !callService.doctor) {
      this.router.navigate([`/${AppPaths.NAVBAR}`]);
    }
  }

  async ngOnInit(): Promise<void> {
    this.navigationService.hideMenu();
    this.navigationService.hideOptions();

    let me:Patient = await this.patientService.getMe()
    this.patient = me;
    this.callService.caller = me;
    const { provider } = me;

    const hasMotive = this.isShortFlow && this.callService.hasMotive() ? true : false;
    this.steps = hasMotive ? 3 : 4

    if (provider.requiresHealthInsuranceInformation) {
      this.steps++;
    }
    const { members } = await this.patientService.getFamilyGroup()
    this.familyGroupMembers = members;
    
    let spinner = this.document.getElementById("pre-loading-spinner")
    if (spinner) spinner.style.display= "none"
  }

  onSelectMember(patient: Patient): void {
    this.callService.caller = patient;
  }

  goToNextStep(): void {
    this.amplitudeService.showEvent(AmplitudeEvents.FAMILY_GROUP_CALLER,
      {
        name: this.callService.caller.name,
        age: this.callService.caller.age,
        email: this.callService.caller.email
      })
    this.navigateToNextStep();
  }

  navigateToNextStep() {
    if(this.isShortFlow && this.callService.hasMotive()) {
      if(this.patient.provider.requiresHealthInsuranceInformation)
        this.router.navigate([AppPaths.NAVBAR,AppPaths.AFFILIATE_INFO]);
      else
        this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.MEDICAL_HISTORY]);
    }
    else
      this.router.navigate([AppPaths.NAVBAR,AppPaths.SELECT_REASON]);
  } 

}
