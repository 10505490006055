import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MedicalCondition } from 'src/app/models/medical-condition.model';
import { MedicalHistoryCategory } from 'src/app/models/medical-history-category.model';

@Component({
  selector: 'app-medical-history-category',
  templateUrl: './medical-history-category.component.html',
  styleUrls: ['./medical-history-category.component.css']
})
export class MedicalHistoryCategoryComponent implements OnChanges {
  @Input() medicalHistoryCategory!: MedicalHistoryCategory;
  @Input() hasMedicalCondition!: boolean;
  @Input() patientMedicalConditions!: Set<string>;
  @Output() onSelectCondition = new EventEmitter<MedicalCondition>();
  @Output() unSelectCondition = new EventEmitter<MedicalCondition>();
  @ViewChild('conditionsList') conditionsList!: MatSelectionList;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasMedicalCondition) {
      if (this.medicalHistoryCategory.code !== 0) {
        this.conditionsList?.deselectAll();
      }
    } else {
      if (this.medicalHistoryCategory.code === 0) {
        this.conditionsList?.deselectAll();
      }
    }
    if (changes.patientMedicalConditions) {
      this.conditionsList?.deselectAll();
      this.conditionsList?.options.forEach((option) => {
        if (this.patientMedicalConditions.has(option.value.id)) {
          option.selected = true;
        }
      });
    }
  }

  selectionChange($event: MatSelectionListChange): void {
    const selectedOption = $event.options[0];
    const selectedCondition = selectedOption.value;
    if (selectedOption.selected) {
      this.onSelectCondition.emit(selectedCondition);
    } else {
      this.unSelectCondition.emit(selectedCondition);
    }
  }

}
