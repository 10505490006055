import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

interface HashKeyResponse {
  patientHashKey: string,
  expirationSeconds: number,
  appointmentURL: string,
}

@Injectable({
  providedIn: 'root'
})
export class BukealaService {

  constructor(
    private requestService: RequestService,
  ) { }

  generatePatientHashKey(idPatient: string): Promise<HashKeyResponse> {
    return this.requestService.get(`/patient/${idPatient}/bukeala/hashKey`);
  }
}
