import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as auth from 'auth0-js';
import { RegistrationService } from 'src/app/services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { nanoid } from 'nanoid';
import { CapacitorService } from 'src/app/services/capacitor.service';

@Component({
  selector: 'app-auth0-registration',
  templateUrl: './auth0-registration.component.html',
  styleUrls: ['./auth0-registration.component.css']
})
export class Auth0RegistrationComponent implements OnInit, OnDestroy {

  auth0: any;
  provider: any;
  patient: any;
  redirectUriCapacitor: string;
  appUrlOpenEventCapacitor!: PluginListenerHandle;

  whiteLabel: boolean;

  constructor(private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private capacitorService: CapacitorService,
    ) {
      this.redirectUriCapacitor = this.capacitorService.getRedirectUri();
      this.whiteLabel = this.registrationService.whiteLabel;
    }

  async ngOnInit(): Promise<void> {
    this.auth0 = this.authService.getAuth0();
    this.provider = this.registrationService.getProvider();
    this.patient = this.registrationService.getPatient();
    if(!this.provider || !this.patient) this.router.navigate([AppPaths.REGISTRATION])

    this.route.fragment.subscribe(async fragment => {
      var params = this.parseParams(fragment);
      if (params.id_token) {
        this.handleRegister(params.id_token);
      } else if (params.error) {
         this.showRegistrationErrorDialog()
       }
    })

    if(Capacitor.isNativePlatform()) {
      this.appUrlOpenEventCapacitor = await CapacitorApp.addListener('appUrlOpen', ({ url }) => {
        this.ngZone.run(async () => {
          if (url?.startsWith(this.redirectUriCapacitor)) {
            const id_token = url?.split('=')[1].split('&')[0];
            if (id_token) {
              await this.handleRegister(id_token);
              Browser.close();
            } else {
              Browser.close();
              this.showRegistrationErrorDialog()
            }
          } else {
            Browser.close();
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    if(Capacitor.isNativePlatform()) {
      this.appUrlOpenEventCapacitor.remove();
    }
  }

  async handleRegister(id_token: string) {
    var validAuthentication = this.authService.validateAuthentication(id_token)
    var auth0Body = this.authService.getBodyFromAuth0(id_token)
    this.registrationService.updatePatient({email: auth0Body.email})
    if (validAuthentication) {
      this.router.navigate([AppPaths.REGISTRATION, AppPaths.DATA_VALIDATION]);
    } else {
      //show dialog or just avoid? (probably an old id_token)
      this.router.navigate([`/${AppPaths.AUTH}`])
    }
  }

  redirectToSocialLogin(connection:string){
    if (Capacitor.isNativePlatform()) {
      const url = this.auth0.client.buildAuthorizeUrl({
        redirectUri: this.redirectUriCapacitor,
        connection: connection,
        responseType: 'id_token',
        nonce: nanoid(16)
      })
      Browser.open({ url, windowName: '_self'})
    } else {
      this.auth0.authorize({
        redirectUri: environment.PWA_URL + '/registration/account',
        connection: connection,
        responseType: 'id_token'
      });
    }
  }

  redirectToMailAndPassword(){
    this.router.navigate([AppPaths.REGISTRATION, AppPaths.EMAIL])
  }

  showRegistrationErrorDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_registration_error_custom",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  parseParams(fragment: string): any {
    if(!fragment) return {};
    var parts = fragment.split('&');
    var params: any = {};
    parts.forEach(part => {
      const pieces = part.split('=');
      params[pieces[0]] = pieces[1];
    });
    return params;
  }

}
