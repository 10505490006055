import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-not-available',
  templateUrl: './doctor-not-available.component.html',
  styleUrls: ['./doctor-not-available.component.css']
})
export class DoctorNotAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
