import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AuthService } from 'src/app/services/auth.service';
import { CapacitorService } from 'src/app/services/capacitor.service';
import { PatientService } from 'src/app/services/patient.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../../dialog/dialog.component';
import { SubscribeDialogComponent } from '../subscribe-dialog/subscribe-dialog.component';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.css']
})
export class UserValidationComponent implements OnInit {

  validationFormGroup = new FormGroup({
    documentNumber: new FormControl('', [Validators.required]),
    reference: new FormControl('', [Validators.required])
  })

  provider: any;
  dialogRef: any;
  auth0: any;

  registrationErrorMessage!: string;
  continueDisabled!: boolean;

  whiteLabel: boolean;
  isNativePlatform: boolean;

  constructor(private patientService: PatientService,
    private registrationService: RegistrationService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private capacitorService: CapacitorService,
  ) {
    this.whiteLabel = this.registrationService.whiteLabel;
    this.isNativePlatform = this.capacitorService.isNativePlatform();
  }

  ngOnInit(): void {
    this.auth0 = this.authService.getAuth0();
    this.provider = this.registrationService.getProvider();
    if (!this.provider) this.router.navigate([AppPaths.REGISTRATION])
  }

  async validateData() {
    this.registrationErrorMessage = "";
    this.continueDisabled = true;
    try {
      let documentNumber = this.validationFormGroup.get('documentNumber')?.value
      let reference = this.validationFormGroup.get('reference')?.value.trim();
      let patient = await this.patientService.validateRegistration(documentNumber, reference, this.provider.id)
      if (patient.hasLoggedIn) {
        this.showHasLoggedInMessage()
        this.continueDisabled = false;
      } else {
        this.registrationService.setPatient(patient)
        this.nextStep();
      }
    } catch (err:any) {
      let invalidData = err.error?.status == 404;
      if (invalidData){
        this.registrationErrorMessage = "registration_error_data_mismatch";
        setTimeout(() => {
          this.openInvitationToSubscribe();
          this.registrationErrorMessage = "";
          this.continueDisabled = false;
        }, 1500)
      } else {
        this.showLoginErrorMessage()
        this.continueDisabled = false;
      }
    }
  }

  openInvitationToSubscribe() {
    this.dialog.open(SubscribeDialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
    })
  }

  showHasLoggedInMessage() {
    let self = this;
    this.dialog.open(DialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_already_registered",
        title: "dialog_title_warning",
        canCancel: true,
        actions: [{
            message: "dialog_redirect_login",
            action: () => this.router.navigate([AppPaths.SOCIAL])
          }]
      },
      disableClose: true
    })
  }

  showLoginErrorMessage() {
    this.dialog.open(DialogComponent, {
      width: "80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_registration_custom_error",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  nextStep() {
    if (this.provider.auth0CustomConnection){
      this.redirectToSocialLogin(this.provider.auth0CustomConnection)
    } else {
      this.router.navigate([AppPaths.REGISTRATION, AppPaths.ACCOUNT])
    }
  }

  redirectToExplanation(){
    this.router.navigate([AppPaths.REGISTRATION, AppPaths.VALIDATOR_EXPLANATION])
  }

  redirectToSocialLogin(connection:string){
    this.auth0.authorize({
      redirectUri: environment.PWA_URL + '/registration/account',
      connection: connection,
      responseType: 'id_token'
    });
  }
}
