import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AuthService } from 'src/app/services/auth.service';
import { PatientService } from 'src/app/services/patient.service';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'app-credential-validation',
  templateUrl: './credential-validation.component.html',
  styleUrls: ['./credential-validation.component.css']
})
export class CredentialValidationComponent implements OnInit {

  constructor(private patientService: PatientService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router) { }

  form: FormGroup = new FormGroup({
    validator: new FormControl('', [Validators.required])
  });

  continueDisabled = false;

  ngOnInit(): void {
  }

  async validate(){
    this.continueDisabled = true;
    var validator = this.form.value.validator;
    try {
      await this.patientService.checkValidatorChange(validator);
      var token = this.authService.getToken();
      await this.authService.handleAuth0Login(token);
      this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
    } catch (error) {
      this.showFailedCredentialDialog();
      this.continueDisabled = false;
    }

  }

  showFailedCredentialDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_reference_number_error",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  redirectToExplanation(){
    this.router.navigate([AppPaths.REGISTRATION, AppPaths.VALIDATOR_EXPLANATION])
  }

}
