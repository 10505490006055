import { Component, OnInit } from '@angular/core';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { PatientService } from 'src/app/services/patient.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { MatDialog } from '@angular/material/dialog';
import { CallFiltersComponent } from '../call-filters/call-filters.component';
import { CallFilter } from 'src/app/models/call-filter.model';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { paginatorCall } from 'src/app/models/paginatorCall.model';

@Component({
  selector: 'app-calls-history',
  templateUrl: './calls-history.component.html',
  styleUrls: ['./calls-history.component.css'],
})
export class CallsHistoryComponent implements OnInit {
  resultCalls!: paginatorCall;
  currentPage = 0;
  detailsPath: string = `/${AppPaths.NAVBAR}/${AppPaths.CALL_DETAIL}/`;
  resultFilter!: CallFilter;
  skip: number = 0;
  limit: number = 25;
  lengthSubject = new Subject<number>();

  constructor(
    private matDialog: MatDialog,
    private patientService: PatientService,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_CALL_HISTORY, {});
    this.getCalls();
  }

  async getCalls(resultFilter?: any): Promise<number> {
    if(resultFilter) {
      resultFilter = this.buildParams(resultFilter);
    }
    this.resultCalls = await this.patientService.getCallsHistory(resultFilter, this.skip, this.limit);
    return this.resultCalls?.total;
  }

  async pageChangeEvent(event: any): Promise<void> {
    this.currentPage = event.pageIndex;
    this.skip = this.currentPage * this.limit;
    this.getCalls(this.resultFilter);
  }

  openCallFilters(): void {
    const dialogRef = this.matDialog.open(CallFiltersComponent, {
      panelClass: 'filter-dialog-container',
      data: this.resultFilter,
      width: '464px'
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.currentPage = 0;
        this.resultFilter = this.removeVoidKeys(result);
        this.skip = 0;
        this.lengthSubject.next(await this.getCalls(result));
      }
    });
  }

  private buildParams(result: any) {
    result = {
      ...result,
      specialty: result.specialty?.realId,
      doctor: result.doctor?.id,
    }
    if (result.from) {
      result.from = moment(result.from).format("DD-MM-YYYY")
    }
    if(result.to) {
      result.to = moment(result.to).format("DD-MM-YYYY")
    }
    return this.removeVoidKeys(result);
  }

  private removeVoidKeys(result: any) {
    Object.keys(result).forEach((key) => !result[key] ? delete result[key] : {});
    return result;
  }
}
