import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Provider } from 'src/app/models/provider.model';
import { RegistrationService } from 'src/app/services/registration.service';
import { App as CapacitorApp, AppState } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';

@Component({
  selector: 'app-token-income',
  templateUrl: './token-income.component.html',
  styleUrls: ['./token-income.component.css']
})
export class TokenIncomeComponent implements OnInit, OnDestroy {
  provider!: Provider;
  redirectURI!: string;
  canOpenApp!: boolean;
  capacitorListener!: PluginListenerHandle;
  appURL!: string;

  constructor(
    private platform: Platform,
    private registrationService: RegistrationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.provider = this.registrationService.getProvider();
    if (this.platform.ANDROID && this.provider.androidPackageName) {
      this.redirectURI = `market://details?id=${this.provider.androidPackageName}`;
    } else if (this.platform.IOS && this.provider.iosBundleId) {
      this.redirectURI = `itms-apps://apple.com/app/${this.provider.iosBundleId}`;
    } else if (this.provider.webUrl) {
      this.redirectURI = this.provider.webUrl;
    }

    if(Capacitor.isNativePlatform()) {
      this.appURL = Capacitor.getPlatform() === 'android' ? 
        this.provider.androidPackageName : this.provider.iosBundleId;

      if(this.appURL) {
        this.canOpenApp = await this.checkCanOpenApp();

        this.capacitorListener = await CapacitorApp.addListener('appStateChange', async (state: AppState) => {
          this.canOpenApp = await this.checkCanOpenApp();
        });
      }
    }
  }

  ngOnDestroy(): void {
    if(Capacitor.isNativePlatform() && this.capacitorListener) {
      this.capacitorListener.remove();
    }
  }

  async onClickOpenApp(): Promise<void> {
    await AppLauncher.openUrl({ url: this.appURL });
  }
  
  async checkCanOpenApp(): Promise<boolean> {
    return (await AppLauncher.canOpenUrl({ url: this.appURL })).value;
  }

}
