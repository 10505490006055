import { Injectable } from '@angular/core';
import * as amplitude from 'amplitude-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor() {
    amplitude.getInstance().init(environment.AMPLITUDE_API_KEY);
  }

  public showEvent(event: string, eventProperties?: Object) {
    amplitude.getInstance().logEvent(event, eventProperties);
  }

  public setUser(id: string) {
    amplitude.getInstance().setUserId(id);
  }
}
