import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Patient } from 'src/app/models/patient.model';
import { Specialty } from 'src/app/models/specialty.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';
import { SpecialtyService } from 'src/app/services/specialty.service';
import { SpecialtySchedulesComponent } from '../specialty-schedules/specialty-schedules.component';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { RegistrationService } from 'src/app/services/registration.service';
import { LocaleService } from 'src/app/services/locale.service';

const specialtiesIcons:any = {
  "Pediatrician": "baby",
  "General doctor": "medicine",
  "Gynecologist/Obstetrician": "gine"
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {

  specialties:any
  me:any;
  locale: string;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private callService: CallService,
    protected patientService: PatientService,
    private specialtyService: SpecialtyService,
    private navigationService: NavigationService,
    public amplitudeService: AmplitudeService,
    private registrationService: RegistrationService,
    private localeService: LocaleService,
  ) {
    this.navigationService.hideBackButton();
    this.navigationService.showMenu();
    this.navigationService.showOptions();
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.patientService.getMe().then((me: Patient) => {
      this.me = me;
      this.patientService.patient = me;
      this.registrationService.setProvider(me.provider);
      this.specialties = this.me.provider.specialties;
      this.amplitudeService.setUser(me.id);
    });
  }

  async specialtySelected(specialty: Specialty){
    this.callService.endCallFlow();
    this.callService.specialty = specialty;
    this.me.familyGroup = await this.patientService.getFamilyGroup();
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_SELECT_SPECIALTY, { specialty: specialty.name })
    if (await this.specialtyService.isAvailable(specialty.realId)) {
      if(this.me.familyGroup.members.length > 1){
        this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.FAMILY_GROUP]);
      } else {
        this.callService.caller = this.me;
        this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.SELECT_REASON]);
      }
    } else {
      this.matDialog.open(SpecialtySchedulesComponent, {
        panelClass: 'custom-dialog-container',
        data: specialty.realId,
        disableClose: true
      });
    }
  }

  ngOnDestroy(): void {
    this.navigationService.showBackButton();
  }

}
