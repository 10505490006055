import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login/login.component';
import { Auth0EmailLoginComponent } from './components/login/auth0-email-login/auth0-email-login.component';
import { Auth0SocialLoginComponent } from './components/login/auth0-social-login/auth0-social-login.component';
import { SocialLoginComponent } from './components/login/social-login/social-login.component';
import { RegistrationComponent } from './components/registration/registration/registration.component';
import { ProviderSelectComponent } from './components/registration/provider-select/provider-select.component';
import { UserValidationComponent } from './components/registration/user-validation/user-validation.component';
import { Auth0RegistrationComponent } from './components/registration/auth0-registration/auth0-registration.component';
import { Auth0EmailRegistrationComponent } from './components/registration/auth0-email-registration/auth0-email-registration.component';
import { DataValidationRegistrationComponent } from './components/registration/data-validation-registration/data-validation-registration.component';
import { DataValidationTokenComponent } from './components/registration/data-validation-token/data-validation-token.component';
import { CredentialValidationComponent } from './components/login/credential-validation/credential-validation.component';
import { ValidatorExplanationComponent } from './components/registration/validator-explanation/validator-explanation.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { WarningComponent } from './components/warning/warning.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FamilyGroupComponent } from './components/family-group/family-group.component';
import { SelectReasonComponent } from './components/select-reason/select-reason.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { HomeComponent } from './components/home/home.component';
import { CallWaitingComponent } from './components/call-waiting/call-waiting.component';
import { CallSuggestionsComponent } from './components/call-suggestions/call-suggestions.component';
import { AppPaths } from './enums/app-paths.enum';
import { CallComponent } from './components/call/call.component';
import { RequiredPermissionsComponent } from './components/required-permissions/required-permissions.component';
import { TokenIncomeComponent } from './components/registration/token-income/token-income.component';
import { OnCallComponent } from './components/on-call/on-call.component';
import { RateCallComponent } from './components/rate-call/rate-call.component';
import { SettingsComponent } from './components/settings/settings.component';
import { MyFamilyGroupComponent } from './components/my-family-group/my-family-group.component';
import { SettingOptionsComponent } from './components/setting-options/setting-options.component';
import { AccountComponent } from './components/account/account.component';
import { CallEndedComponent } from './components/call-ended/call-ended.component';
import { CallsHistoryComponent } from './components/calls-history/calls-history.component';
import { AuthGuard } from './guards/auth.guard';
import { AppointmentGuard } from './guards/appointment.guard';
import { CallDetailComponent } from './components/call-detail/call-detail.component';
import { DoctorSearchComponent } from './components/doctor-search/doctor-search.component';
import { DoctorDetailComponent } from './components/doctor-detail/doctor-detail.component';
import { DownloadFilesComponent } from './components/download-files/download-files.component';
import { PasswordResetComponent } from './components/login/password-reset/password-reset.component';
import { RequestSentComponent } from './components/login/request-sent/request-sent.component';
import { RequestAffiliateInfoComponent } from './components/request-affiliate-info/request-affiliate-info.component';
import { HideSpinnerGuard } from './guards/hide-spinner.guard';
import { AuthHideSpinnerGuard } from './guards/auth-hide-spinner.guard';
import { BukealaAppointmentComponent } from './components/bukeala-appointment/bukeala-appointment.component';

const routes: Routes = [
  { path: AppPaths.AUTH, component: LoginComponent },
  { path: AppPaths.SOCIAL, component: SocialLoginComponent, canActivate: [HideSpinnerGuard],
    children:[
      { path:'', component: Auth0SocialLoginComponent },
      { path: AppPaths.EMAIL, component: Auth0EmailLoginComponent },
      { path: AppPaths.PASSWORD_RESET, component: PasswordResetComponent },
      { path: AppPaths.REQUEST_PASSWORD_SENT, component: RequestSentComponent }
    ]
  },
  { path: AppPaths.CREDENTIAL_VALIDATION, component: CredentialValidationComponent, canActivate: [HideSpinnerGuard]},
  { path: AppPaths.REGISTRATION, component: RegistrationComponent, canActivate: [HideSpinnerGuard],
    children:[
      { path:'', component: ProviderSelectComponent },
      { path: AppPaths.VALIDATION, component: UserValidationComponent},
      { path: AppPaths.ACCOUNT, component: Auth0RegistrationComponent},
      { path: AppPaths.EMAIL, component: Auth0EmailRegistrationComponent},
      { path: AppPaths.DATA_VALIDATION, component: DataValidationRegistrationComponent},
      { path: AppPaths.VALIDATOR_EXPLANATION, component: ValidatorExplanationComponent},
      { path: AppPaths.TOKEN_INCOME, component: TokenIncomeComponent},
    ]
  },
  { path: AppPaths.DATA_VALIDATION, component: DataValidationTokenComponent,  canActivate: [AuthGuard, AuthHideSpinnerGuard]},
  { path: AppPaths.TERMS_AND_CONDITIONS, component: TermsAndConditionsComponent,  canActivate: [AuthGuard, AuthHideSpinnerGuard] },
  { path: AppPaths.WARNING, component: WarningComponent, canActivate: [AuthGuard, AuthHideSpinnerGuard]},
  { path: AppPaths.DOCTOR_DETAIL+"/:id", component: DoctorDetailComponent, canActivate: [AuthGuard, AuthHideSpinnerGuard]},
  { path: AppPaths.NAVBAR, component: NavbarComponent, canActivate: [AuthGuard, AuthHideSpinnerGuard],
    children:[
      { path: '', redirectTo: AppPaths.HOME, pathMatch: 'full' },
      { path: AppPaths.HOME, component: HomeComponent, canActivate: [AppointmentGuard] },
      { path: AppPaths.CALLS_HISTORY, component: CallsHistoryComponent, canActivate: [AppointmentGuard] },
      { path: `${AppPaths.CALL_DETAIL}/:id`, component: CallDetailComponent, canActivate: [AppointmentGuard] },
      { path: `${AppPaths.DOWNLOAD}/:id`, component: DownloadFilesComponent, canActivate: [AppointmentGuard] },
      { path: AppPaths.FAMILY_GROUP, component: FamilyGroupComponent},
      { path: AppPaths.SELECT_REASON, component: SelectReasonComponent},
      { path: AppPaths.MEDICAL_HISTORY, component: MedicalHistoryComponent},
      { path: AppPaths.AFFILIATE_INFO, component: RequestAffiliateInfoComponent},
      { path: AppPaths.DOCTORS, component: DoctorSearchComponent, canActivate: [AppointmentGuard]},
      { path: AppPaths.BUKEALA_APPOINTMENT, component: BukealaAppointmentComponent},
    ]
  },
  { path: AppPaths.SETTINGS, component: SettingsComponent, canActivate: [AuthGuard, AuthHideSpinnerGuard, AppointmentGuard],
    children:[
      { path: '', component: SettingOptionsComponent},
      { path: AppPaths.FAMILY_GROUP, component: MyFamilyGroupComponent},
      { path: AppPaths.TERMS_AND_CONDITIONS, component: MyFamilyGroupComponent},
      { path: AppPaths.SETTINGS, component: SettingsComponent},
      { path: AppPaths.ACCOUNT, component: AccountComponent},
    ]
  },
  { path: AppPaths.CALL, component: CallComponent, canActivate: [AuthGuard, AuthHideSpinnerGuard],
    children: [
      { path: AppPaths.CALL_SUGGESTIONS, component: CallSuggestionsComponent},
      { path: AppPaths.CALL_PERMISSIONS, component: RequiredPermissionsComponent},
      { path: AppPaths.CALL_WAITING, component: CallWaitingComponent},
      { path: AppPaths.CALL_RATE, component: RateCallComponent},
      { path: AppPaths.CALL_ENDED, component: CallEndedComponent},
      { path: AppPaths.ON_CALL, component: OnCallComponent}
    ]
  },
  { path: AppPaths.APPOINTMENT_END, component: CallEndedComponent, canActivate: [HideSpinnerGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
