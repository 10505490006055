import { Component, HostBinding, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { PatientService } from 'src/app/services/patient.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import * as moment from 'moment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CallService } from 'src/app/services/call.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Patient } from 'src/app/models/patient.model';
import { RegistrationService } from 'src/app/services/registration.service';
import { CapacitorService } from 'src/app/services/capacitor.service';

declare var LukesLazyLoader: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @HostBinding('class')
  hasConfetti = 'auth-confetti';
  @HostBinding('class.loaded')
  loaded = true;
  private subscription!: Subscription;

  socialPath = AppPaths.SOCIAL;
  registrationPath = AppPaths.REGISTRATION;

  isNativePlatform: boolean;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private callService: CallService,
    public jwtHelper: JwtHelperService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
    private appointmentService: AppointmentService,
    private registrationService: RegistrationService,
    private capacitorService: CapacitorService,
    @Inject(DOCUMENT) private document: Document,
  ) { 
    this.isNativePlatform = this.capacitorService.isNativePlatform();
  }

  ngOnInit(): void {

    //Dejar la logica de login de token en este componente o moverla a un service?
    this.subscription = this.route.queryParams.subscribe(async (params) => {
      if (params.token) {
        this.loaded = false;
        localStorage.clear();

        if (params.loaderSpinnerColor) {
          localStorage.setItem("loaderSpinnerColor", params.loaderSpinnerColor.startsWith("rgb") ? decodeURI(params.loaderSpinnerColor) : `#${params.loaderSpinnerColor}` );
        } else {
          localStorage.removeItem("loaderSpinnerColor");
        }

        if (params.loaderBackgroundInAppColor) {
          localStorage.setItem("loaderBackgroundInAppColor", params.loaderBackgroundInAppColor.startsWith("rgb") ? decodeURI(params.loaderBackgroundInAppColor) : `#${params.loaderBackgroundInAppColor}` );
        } else {
          localStorage.removeItem("loaderBackgroundInAppColor");
        }

        if (params.showReasonInput) {
          localStorage.setItem("showReasonInput", params.showReasonInput);
        } else {
          localStorage.removeItem("showReasonInput");
        }

        var validAuthentication = this.authService.validateAuthentication(
          params.token
        );
        if (validAuthentication) {
          const shortFlow = params.shortFlow === 'true' || false;
          const motive = params.motive ? decodeURI(params.motive) : undefined;
          this.loginByToken(
            params.token,
            params.appointment,
            shortFlow,
            motive,
            params.specialty
          );
        } else {
          this.loaded = true;
          this.openSessionExpiredDialog(params.token, params.appointment);
        }
      } else {
        const patient: Patient | null = this.patientService.patient;
        if (this.authService.isAuthenticated() && patient?.id) {
          const me = await this.patientService.getMeFromServer();
          this.registrationService.setPatient(me);
          this.registrationService.setProvider(me.provider);
          this.removePreLoadSpinner()
          this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
        } else {
          this.removePreLoadSpinner()
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async loginByToken(
    token: string,
    appointmentId?: string,
    shortFlow?: boolean,
    motive?: string,
    specialtyRealId?: string
  ) {
    await this.authService.handleTokenLogin(token, appointmentId);

    const me = await this.patientService.getMe();
    this.amplitudeService.setUser(me.id);
    this.amplitudeService.showEvent(AmplitudeEvents.LOGIN_TOKEN, { token });
    const provider = me.provider;
    this.registrationService.setProvider(provider);

    if (provider.customStyleSheetUrl) {
      this.loadCustomCSS(provider.customStyleSheetUrl);
    } else {
      this.loaded = true;
    }
    if (appointmentId) {
      var appointment = await this.appointmentService.validateAppointment(
        appointmentId
      );
      if (!appointment.valid) {
        this.openAppointmentErrorDialog(appointment.message);
      } else {
        this.appointmentService.setAppointment(appointmentId);
        this.removePreLoadSpinner()
        this.router.navigate([AppPaths.DATA_VALIDATION]);
      }
    } else {
      if (shortFlow) {
        const providerSpecialties = (await this.patientService.getProvider())
          .specialties;
        let specialty = providerSpecialties.find(
          (s) => s.realId == specialtyRealId
        );
        if (!specialty) {
          specialty = providerSpecialties[0];
        }
        this.callService.specialty = specialty;
        localStorage.setItem('isShortFlow', 'true');
        if (motive) {
          localStorage.setItem('motive', motive);
          this.callService.reason = motive;
        }
        await this.goToNextStepShortFlow(me);
      } else {
        // Send token patient to registration second every time they log in.
        this.removePreLoadSpinner()
        this.router.navigate([AppPaths.DATA_VALIDATION]);
      }
    }

  }

  async goToNextStepShortFlow(me: Patient): Promise<void> {
    if (await this.patientService.hasFamilyGroup())
      this.router.navigate([AppPaths.NAVBAR, AppPaths.FAMILY_GROUP]); // step 1
    else {
      this.callService.caller = me;
      if (this.callService.hasMotive()) {
        if (me.provider.requiresHealthInsuranceInformation)
          this.router.navigate([AppPaths.NAVBAR, AppPaths.AFFILIATE_INFO]); // step 3
        else
          this.router.navigate([AppPaths.NAVBAR, AppPaths.MEDICAL_HISTORY]); // step 4
      } else
        this.router.navigate([AppPaths.NAVBAR, AppPaths.SELECT_REASON]); //step 2
    }
  }

  openSessionExpiredDialog(token: string, appointment?: string) {
    this.dialog.closeAll();
    this.removePreLoadSpinner()
    var appointmentMessage;
    if (appointment) appointmentMessage = this.getAppointmentMessage(token);
    var message = appointmentMessage || 'dialog_error_token_expired';
    this.dialog.open(DialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
      data: {
        message: message,
        title: 'dialog_title_warning',
        canCancel: false,
      },
      disableClose: true,
    });
  }

  openAppointmentErrorDialog(message: string) {
    this.removePreLoadSpinner()
    this.dialog.open(DialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
      data: {
        message: message,
        title: 'dialog_title_warning',
        canCancel: false,
      },
      disableClose: true,
    });
  }

  getAppointmentMessage(token: string) {
    var decodedToken = this.jwtHelper.decodeToken(token);
    var notBefore = moment.unix(Number(JSON.stringify(decodedToken.nbf)));
    if (moment().isBefore(notBefore)) {
      return 'dialog_appointment_error_not_started';
    }
    return 'dialog_appointment_error_ended';
  }

  loadCustomCSS(customStyleSheetUrl: string): void {
    LukesLazyLoader.load(
      { url: customStyleSheetUrl, type: 'css', args: { async: true } }, () => {
        console.log('custom.css loaded');
        this.loaded = true;
      }
    );
  }

  removePreLoadSpinner() {
    let spinner = this.document.getElementById("pre-loading-spinner")
    if (spinner) spinner.style.display = "none"
  }
}
