<div class="container">
    <!-- header(negro) -->
    <app-header></app-header>

    <!-- routeroutlet (rosa) -->
    <div class="router">
        <router-outlet>
            <!-- componente pantalla (verdeclaro) -->
        </router-outlet>
    </div>

    <!-- menu(azul) -->
    <div *ngIf="showMenu$ | async" class="menu">
        <div class="menu-item" (click)="select(i)" [routerLink]="item.path" *ngFor='let item of menuOptions; let i = index'>
            <mat-icon [svgIcon]="item.img"></mat-icon>
            <span [ngClass]="{'selected': selected==i }">{{item.text | locale }}</span>
        </div>
    </div>

</div>
