import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Doctor } from 'src/app/models/doctor.model';

@Component({
  selector: 'app-doctor-schedules',
  templateUrl: './doctor-schedules.component.html',
  styleUrls: ['./doctor-schedules.component.css'],
})
export class DoctorSchedulesComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public doctor: Doctor
  ) {}

  ngOnInit(): void {}
}
