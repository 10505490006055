import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalePipe } from 'src/app/locale.pipe';
import { SNACK_BAR_DEFAULT_OPTIONS } from 'src/app/consts/snack-bar-default-options.const';
import { PatientService } from 'src/app/services/patient.service';
import { AuthService } from 'src/app/services/auth.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { AppPaths } from 'src/app/enums/app-paths.enum';

@Component({
  selector: 'app-data-validation-registration',
  templateUrl: './data-validation-registration.component.html',
  styleUrls: ['./data-validation-registration.component.css'],
  providers: [LocalePipe]
})
export class DataValidationRegistrationComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private localePipe: LocalePipe,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private patientService: PatientService,
    private registrationService: RegistrationService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      // If the user exists, cancel the registration
      const auth0User = await this.patientService.getMeFromServer();
      if (auth0User) {
        this.matSnackBar.open(this.localePipe.transform('registration_user_exists'), undefined, {
          ...SNACK_BAR_DEFAULT_OPTIONS,
          panelClass: ['toast', 'error'],
        });
        this.authService.logout();
        this.router.navigate([`/${AppPaths.AUTH}`])
      }
    } catch (error) {
      // If the user is not registered, continue
      console.log(`Error: ${error}. User not exists`);
    }
  }

  async updateData(updatedPatient: any) {
    let patient = this.registrationService.getPatient();
    updatedPatient.phone = updatedPatient.phone.e164Number;
    patient = { ...patient, ...updatedPatient };
    const { email } = this.authService.jwtHelper.decodeToken(this.authService.getToken());
    let affiliate: any = {
      provider_id: patient.provider.id,
      validator: patient.validator,
      documentNumber: patient.documentNumber,
      phone: patient.phone,
      gender: patient.gender,
      date: patient.birthDate,
      name: patient.name,
    };
    if (email && email !== patient.email && !updatedPatient.email) {
      affiliate.email = email;
      patient.email = email;
    }
    try {
      patient = await this.patientService.create(affiliate);
      this.registrationService.setPatient(patient);
      this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
    } catch (err) {
      this.showCustomErrorDialog();
    }
  }

  showCustomErrorDialog() {
    this.dialog.open(DialogComponent, {
      width: '80%',
      panelClass: 'custom-dialog-container',
      data: {
        message: 'dialog_data_validation_custom_error',
        title: 'dialog_title_warning',
        canCancel: true,
      },
    });
  }
}
