import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { CallService } from 'src/app/services/call.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-call-ended',
  templateUrl: './call-ended.component.html',
  styleUrls: ['./call-ended.component.css']
})
export class CallEndedComponent implements OnInit, OnDestroy {

  messageTitle = "";
  messageBody1 = "";
  messageBody2 = "";

  isShortFlow: boolean = false;
  inAppointment: boolean = false;

  constructor(
    private router: Router,
    private callService: CallService,
    private navigationService: NavigationService,
    private appointmentService: AppointmentService
  ) { }

  ngOnInit(): void {
    window.top.postMessage({ type: 'call_rated' }, '*');
    this.inAppointment = this.appointmentService.inAppointment();
    this.isShortFlow = localStorage.getItem('isShortFlow') === 'true';
    this.setMessages();
    this.navigationService.hideBackButton();
    this.navigationService.hideOptions();
  }

  ngOnDestroy(): void {
    this.navigationService.showBackButton();
    this.navigationService.showOptions();
  }

  goToHome(): void {
    this.callService.endCallFlow();
    this.router.navigate([`/${AppPaths.NAVBAR}`]);
  }

  setMessages() {
    if (this.inAppointment) {
      switch(this.appointmentService.getAppointmentStatus()){
        case "ended":
          this.messageTitle = "appointment_end_success_title";
          this.messageBody1 = "appointment_end_success_body";
          break;
        case "notAvailableDoctor":
          this.messageTitle = "appointment_end_notAvailableDoctor";
          break;
        default:
          this.messageTitle = "appointment_end_timeout";
          break;
      }
    } else if (this.isShortFlow) {
      this.messageTitle = "call_ended_short_flow_title";
      this.messageBody1 = "call_ended_short_flow_body";
    } else {
      this.messageTitle = "call_ended_title";
      this.messageBody1 = "call_ended_description_1";
      this.messageBody2 = "call_ended_description_2";

    }
  }

}
