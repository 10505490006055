<div class="card">
  <div class="message">
    <p>
      {{ "request_sent_message_1" | locale }}
      <strong>
        {{ email }}
      </strong>
      {{ 'request_sent_message_2' | locale }}
    </p>
    <mat-icon class="material-icons-round">check_circle</mat-icon>
  </div>
  <button mat-flat-button color="primary" routerLink="/">
    {{ 'call_ended_go_to_home' | locale }}
  </button>
  <div class="not-received">
    <p>
      {{ 'request_not_received' | locale }}
      <button
        mat-button
        color="accent"
        [disableRipple]="true"
        class="no-focus-overlay"
        (click)="resetPassword()">
        {{ 'request_resend' | locale }}
      </button>
    </p>
  </div>
</div>
