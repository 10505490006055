import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { NavigationService } from 'src/app/services/navigation.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { environment } from 'src/environments/environment';
import { PatientService } from 'src/app/services/patient.service';
import { Provider } from 'src/app/models/provider.model';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  @HostBinding('style.--logo-width')
  logoWidth = '110px';
  @Input()
  showSetting: Boolean = true;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  private urlOfSetting: Array<String> = [AppPaths.SETTINGS, AppPaths.TERMS_AND_CONDITIONS];
  settingsPath = AppPaths.SETTINGS
  faqURL = environment.FAQ_URL;
  provider!: Provider;
  providerTitle!: string;
  useWhiteLabel!: boolean;
  
  constructor(
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    public amplitudeService: AmplitudeService,
    private patientService: PatientService,
    private registrationService: RegistrationService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 960px)');
    this.mobileQueryListener = () => {
      changeDetectorRef.detectChanges()
      this.logoWidth = this.mobileQuery.matches ? '70px' : '110px';
    };
    // Not all browsers support addEventListener
    this.logoWidth = this.mobileQuery.matches ? '70px' : '110px';
    if (this.mobileQuery.addEventListener)
      this.mobileQuery.addEventListener("change", this.mobileQueryListener);
  }

  async ngOnInit(): Promise<void> {
    this.provider = await this.patientService.getProvider();

    this.useWhiteLabel = this.registrationService.whiteLabel;

    if(this.useWhiteLabel)
      this.providerTitle = this.provider.title || this.provider.name;
    
    this.showSetting = this.route.snapshot.url.find(
      (item) => (this.findNewUrl(item.path))) ? false : true;
  }

  ngOnDestroy(): void {
    if (this.mobileQuery.removeEventListener)
      this.mobileQuery.removeEventListener("change", this.mobileQueryListener);
  }
  findNewUrl(url: String) {
    return this.urlOfSetting.find((item) => (item === url));
  }
  showEvent() {
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_SETTINGS,{});
  }

}
