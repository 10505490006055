import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  doctors = []
  doctorsLimit = 5;
  starsRate = [0,0,0,0,0]
  results: any[] = [];

  constructor(private requestService: RequestService, private httpClient: HttpClient) { }

  searchDoctors(query : string, limit? : number, skip? : number){
    let criteria:any = { name: query };

    if(limit) {
      criteria.limit = limit
    };

    if(skip) {
      criteria.skip = skip
    };

    return this.httpClient.get<any[]>(`${environment.API_URL}/doctor?sort=name ASC`, { params: criteria })
  }

  loadDoctors(){
    return this.requestService.get('/doctor?sort=name ASC')
  }

  loadFavouritedDoctors(){
    return this.requestService.get('/patient?sort=name ASC')
  }

  async getDoctor(id:string): Promise<any>{
    return this.requestService.get(`/doctor/${id}`)
  }
}
