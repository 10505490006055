import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private menuSubject = new BehaviorSubject<boolean>(true);

  get menu$() {
    return this.menuSubject.asObservable();
  }

  private optionsSubject = new BehaviorSubject<boolean>(true);

  get options$() {
    return this.optionsSubject.asObservable();
  }

  private backButtonSubject = new BehaviorSubject<boolean>(true);

  get backButton$() {
    return this.backButtonSubject.asObservable();
  }

  constructor() { }

  showMenu() {
    this.menuSubject.next(true);
  }

  hideMenu() {
    this.menuSubject.next(false);
  }

  showOptions() {
    this.optionsSubject.next(true);
  }

  hideOptions() {
    this.optionsSubject.next(false);
  }

  showBackButton() {
    this.backButtonSubject.next(true);
  }

  hideBackButton() {
    this.backButtonSubject.next(false);
  }
}
