<mat-expansion-panel
  *ngIf="medicalHistoryCategory.medicalConditions.length > 1; else selectionList"
  [ngClass]="{'has-selection': conditionsList?.selectedOptions?.hasValue() || false }">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ medicalHistoryCategory.name }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container [ngTemplateOutlet]="selectionList"></ng-container>
</mat-expansion-panel>

<ng-template matExpansionPanelContent #selectionList>
  <mat-selection-list
    color="primary"
    #conditionsList
    (selectionChange)="selectionChange($event)">
    <mat-list-option
      [value]="condition"
      *ngFor="let condition of medicalHistoryCategory.medicalConditions">
      <p matLine>
        {{ condition.name }}
      </p>
    </mat-list-option>
  </mat-selection-list>
</ng-template>
