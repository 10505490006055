<h2 class="title">{{'login_social_title'|locale}}</h2>
<div class="login-options">
    <div class="buttons-container">
        <div class="title-container">
            <mat-divider></mat-divider>
            <span>
                {{"social_login_enter" | locale}}
            </span>
            <mat-divider></mat-divider>
        </div>
        <button mat-raised-button class="login-button google-button" (click)="redirectToSocialLogin('google-oauth2')">
            <mat-icon svgIcon="google"></mat-icon>
            {{"social_login_google" | locale}}
        </button>
        <button mat-raised-button class="login-button outlook-button" (click)="redirectToSocialLogin('windowslive')">
            <mat-icon svgIcon="outlook"></mat-icon>
            {{"social_login_outlook" | locale}}
        </button>
        <button mat-raised-button class="login-button apple-button" (click)="redirectToSocialLogin('apple')">
            <mat-icon svgIcon="apple"></mat-icon>
            {{"social_login_apple" | locale}}
        </button>
        <button mat-raised-button class="login-button google-button" (click)="redirectToSocialLogin(psConnection)">
          <mat-icon svgIcon="ps"></mat-icon>
          {{"Prevención Salud"}}
        </button>
        <button color="primary" mat-raised-button class="login-button mail-button" (click)="redirectToMailAndPassword()">
            {{"social_login_email" | locale}}
        </button>
        <p>
          {{ "social_login_account" | locale }}
          <a routerLink="{{'/'+registrationPath}}">
            {{ "social_login_sigin" | locale }}
          </a>
        </p>
        <mat-divider></mat-divider>
        <p>
          {{ "social_login_problems" | locale }}
          <a href="mailto:contacto@llamandoaldoctor.com.ar" target="_blank">
            {{ "social_login_contact_us" | locale }}
          </a>
        </p>
    </div>
</div>
