import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CapacitorService } from 'src/app/services/capacitor.service';

@Component({
  selector: 'app-rate-store',
  templateUrl: './rate-store.component.html',
  styleUrls: ['./rate-store.component.css']
})
export class RateStoreComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RateStoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, title: string, closeMessage: string, canCancel: boolean, actions: any }) { 
    }

  ngOnInit(): void {
  }

}
