import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.css']
})
export class SelectItemComponent implements OnInit {

  @Input() itemText:string = 'text'
  @Input() itemSubtext:string = 'subtext'
  @Input() color:string = 'black'
  @Input() iconName?:string
  @Input() srcIcon?:string;
  @Input() south:boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
