import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { LocalePipe } from 'src/app/locale.pipe';
import { CallData } from 'src/app/models/call-data.model';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { HealthInsurance } from 'src/app/models/healt-insurance.model';
import { Patient } from 'src/app/models/patient.model';
import { CallService } from 'src/app/services/call.service';
import { HealthInsuranceService } from 'src/app/services/health-insurance.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-request-affiliate-info',
  templateUrl: './request-affiliate-info.component.html',
  styleUrls: ['./request-affiliate-info.component.css'],
  providers: [LocalePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestAffiliateInfoComponent implements OnInit, OnDestroy {
  patient!: Patient;
  subscription: Subscription;
  formGroup!: FormGroup;
  notHealthInsurance: HealthInsurance;
  healthInsurances: HealthInsurance[] = [];
  healthInsurances$ = of<HealthInsurance[]>([]);
  displayNone = false;
  hasFamilyGroup = false;
  hasMotive = false;
  steps!: number;
  currentStepIndex!: number;
  notHealthInsuranceKey = 'affiliate_no_health_insurance';
  notHealthInsuranceText = this.localePipe.transform(this.notHealthInsuranceKey);

  get healthInsuranceControl(): FormControl {
    return this.formGroup?.get('healthInsurance') as FormControl;
  }

  get credentialControl(): FormControl {
    return this.formGroup?.get('credential') as FormControl;
  }

  constructor(
    private router: Router,
    private localePipe: LocalePipe,
    private callService: CallService,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private navigationService: NavigationService,
    private healthInsuranceService: HealthInsuranceService
  ) {
    this.subscription = this.callService.callData$.subscribe({
      next: ({ caller }: CallData) => {
        this.patient = caller;
      },
    });
    this.navigationService.hideMenu();
    this.navigationService.hideOptions();
    
    this.notHealthInsurance = {
      id: this.notHealthInsuranceText,
      name: this.notHealthInsuranceText,
      contry: this.notHealthInsuranceText,
    };

    this.patientService.getFamilyGroup().then(({ members }: FamilyGroup) => {
      const hasFamilyGroup = members.length > 1;

      this.steps = hasFamilyGroup ? 5 : 4;
      this.currentStepIndex= hasFamilyGroup ? 2 : 1;

      const isShortFlow = this.patientService.hasShortFlow();
      if(isShortFlow && this.callService.hasMotive()) {
        this.steps--;
        this.currentStepIndex--;
      }
    });
  }

  ngOnInit(): void {
    this.getData();
    this.setupForm();
  }

  async getData(): Promise<void> {
    this.healthInsurances =
      await this.healthInsuranceService.getHealthInsurances();
    this.subscribeToValueChanges();
  }

  setupForm(): void {
    const { credential } = this.patient;
    let { healthInsurance } = this.patient;

    if(healthInsurance === this.notHealthInsuranceKey) {
      healthInsurance = this.notHealthInsuranceText
    }
    this.formGroup = this.formBuilder.group({
      healthInsurance: [
        healthInsurance ? healthInsurance : '',
        [
          Validators.required,
          Validators.minLength(3),
          this.isAValidHealthInsurance.bind(this),
        ],
      ],
      credential: [credential ? credential : ''],
    });
    this.credentialControl.disable();
    this.healthInsuranceControl?.statusChanges.subscribe((status: string) => {
      if (status === 'VALID') {
        this.credentialControl.enable();
      } else {
        this.credentialControl.disable();
      }
    });
  }

  subscribeToValueChanges(): void {
    this.healthInsurances$ = this.healthInsuranceControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map((value: string) => this.filterHealthInsurances(value))
    );
  }

  isAValidHealthInsurance(
    control: AbstractControl
  ): ValidationErrors | null {
    const healthInsurance = control.value;
    this.displayNone = healthInsurance === this.notHealthInsuranceText;
    if (this.displayNone) {
      this.credentialControl?.setValidators([]);
      this.credentialControl?.reset();
      this.credentialControl?.disable();
      return null;
    }
    if (healthInsurance) {
      this.credentialControl?.setValidators([Validators.required]);
      this.credentialControl?.enable();
      return null;
    }
    return { invalidHealthInsurance: true };
  }

  displayNameFn({ name }: HealthInsurance): string {
    return name;
  }

  filterHealthInsurances(value: string): HealthInsurance[] {
    if (!value || value.length === 0) {
      return [this.notHealthInsurance, ...this.healthInsurances];
    }
    return [this.notHealthInsurance, ...this.healthInsurances.filter(({ name }: HealthInsurance) =>
      name.trim().toLowerCase().includes(value.toLowerCase())
    )];
  }

  async submitForm(): Promise<void> {
    if (!this.formGroup.valid) {
      return;
    }
    let healthInsurance = this.formGroup.value;
    if(healthInsurance.healthInsurance === this.notHealthInsuranceText) {
      healthInsurance.healthInsurance = this.notHealthInsuranceKey;
    }
    this.patient = { ...this.patient, ...healthInsurance };
    await this.patientService.update(this.patient.id, healthInsurance);
    const owner = await this.patientService.getMe();
    this.callService.caller = this.patient;
    if (owner.id === this.patient.id) {
      this.patientService.patient = this.patient;
    } else {
      const patientIndex =  (owner.familyGroup as FamilyGroup).members.findIndex(({ id }: Patient) => id === this.patient.id);
      (owner.familyGroup as FamilyGroup).members[patientIndex] = this.patient;
      this.patientService.patient = owner;;
    }
    this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.MEDICAL_HISTORY]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
