import { Injectable } from '@angular/core';
import { Patient } from '../models/patient.model';
import { Provider } from '../models/provider.model';
import { RequestService } from './request.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private requestService: RequestService) { }

  get patient(): any {
    const patient = localStorage.getItem('patient');
    return patient ? JSON.parse(patient) : null;
  }

  set patient(patient: any) {
    localStorage.setItem('patient', JSON.stringify(patient));
  }

  async getMe(): Promise<Patient> {
    if(this.patient) return this.patient
    let patient = await this.getMeFromServer();
    if(patient) this.patient = patient;
    return this.patient
  }

  async getMeFromServer(): Promise<Patient> {
    return this.requestService.get('/me')
  }

  auth() {
    return this.requestService.get('/patient/auth')
  }

  create(patient: any) {
    return this.requestService.post('/patient', patient)
  }

  ping() {
    return this.requestService.post(`/patient/ping`, {})
  }

  update(patientId:string, updateData: any) {
    return this.requestService.put('/affiliate/' + patientId, updateData)
  }
  async validateRegistration(documentNumber: string, reference: string, providerId: string) {
    return await this.requestService.get('/affiliate/' + documentNumber, {
      validator: reference,
      provider: providerId})
  }
  checkValidatorChange(validator:string){
    return this.requestService.get('/affiliate/validator/'+validator)
  }

  async getProvider(): Promise<Provider> {
    const me = await this.getMe();
    return me.provider;
  }

  async getFamilyGroup(): Promise<any> {
    const me = await this.getMe();
    if (typeof me.familyGroup === 'string') {
      me.familyGroup = await this.requestService.get(`/familyGroup/${me.familyGroup}`);
      this.patient = me;
    }
    return this.patient.familyGroup;
  }

  async hasFamilyGroup(): Promise<boolean>{
    let familyGroup = await this.getFamilyGroup();
    return familyGroup.members.length > 1
  }

  async getCallsHistory(params?: { [key: string]: any }, skip: number = 0, limit: number = 25, findAndCount: boolean = true): Promise<any> {
    const me = await this.getMe();

    let queryParams:any = {
      limit : limit,
      skip : skip,
      status: ["ENDED", "ENDED_MANUAL", "ENDED_PATIENT", "ENDED_DOCTOR", "CONNECTION_FAILED", "CONNECTION_LOST"]
    }

    queryParams.from = params?.from || moment(me.createdAt).format("DD-MM-YYYY")
    queryParams.to = params?.to || moment(Date.now()).format("DD-MM-YYYY");

    if(findAndCount) {
      queryParams.findAndCount = findAndCount;
    }

    return this.requestService.get(`/patient/${me.id}/calls`, {...queryParams, ...params} )
  }

  async getFavouritedDoctors(): Promise<any> {
    const me = await this.getMe();

    return this.requestService.get(`/patient/${me.id}/doctors/`);
  }

  async addDoctorToFavourites(doctorId: string) {
    const me = await this.getMe();
    return this.requestService.put(`/patient/${me.id}/doctor/${doctorId}`, {})
  }

  async removeDoctorFromFavourites(doctorId: string) {
    const me = await this.getMe();
    return this.requestService.delete(`/patient/${me.id}/doctor/${doctorId}`)
  }

  setTyCAccepted(version:number){
    return this.requestService.put(`/patient/termsAndConditionsAccepted`, {version: version})
  }

  hasShortFlow(): boolean {
    return localStorage.getItem('isShortFlow') === 'true';
  }

}
