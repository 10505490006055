import { Component, Input, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-call-history-item',
  templateUrl: './call-history-item.component.html',
  styleUrls: ['./call-history-item.component.css']
})
export class CallHistoryItemComponent implements OnInit {

  @Input() call:any

  locale:string = "";

  constructor(private localeService:LocaleService) { }

  ngOnInit(): void {
    this.locale = this.localeService.getLocale();
  }

}
