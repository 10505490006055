import { Injectable } from '@angular/core';
import * as momentTimezone from 'moment-timezone';
import { SpecialtyConfig } from '../models/specialty-config.model';
import { Specialty } from '../models/specialty.model';
import { PatientService } from './patient.service';
import { ProviderService } from './provider.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialtyService {
  private readonly DEFAULT_TIMEZONE = 'America/Buenos_Aires';
  private specialties!: Specialty[];
  private specialtyConfigs!: SpecialtyConfig[];

  constructor(
    private patientService: PatientService,
    private providerService: ProviderService
  ) {}

  public async getSpecialties(): Promise<Specialty[]> {
    if (this.specialties && this.specialties.length > 0) {
      return this.specialties;
    }
    this.specialties =
      (await this.patientService.getMe()).provider.specialties || [];
    return this.specialties;
  }

  public async getSpecialtyConfigs(): Promise<SpecialtyConfig[]> {
    if (this.specialtyConfigs && this.specialtyConfigs.length > 0) {
      return this.specialtyConfigs;
    }
    this.specialtyConfigs =
      (await this.providerService.getProviderSpecialtyConfig(
        (
          await this.patientService.getMe()
        ).provider.id
      )) || [];
    return this.specialtyConfigs;
  }

  public async getSpecialtyConfigById(
    id: string
  ): Promise<SpecialtyConfig | undefined> {
    return (await this.getSpecialtyConfigs()).find(
      (specialtyConfig) => specialtyConfig.specialty === id
    );
  }

  public async isAvailable(specialty: Specialty | string): Promise<boolean> {
    let scheduleConfiguration: SpecialtyConfig;

    scheduleConfiguration = (await this.getSpecialtyConfigById(
      typeof specialty === 'string' ? specialty : specialty.id
    ))!;

    if (scheduleConfiguration) {
      const { availableHours, timezone } = scheduleConfiguration;
      if (Object.keys(availableHours || {}).length > 0) {
        const now = momentTimezone.tz(timezone || this.DEFAULT_TIMEZONE);
        const day = now.day();
        return Object.entries<number[]>(availableHours).some(
          ([range, days]: [string, number[]]) => {
            let [start, end] = range.split('-');
            const startTime = now
              .clone()
              .hour(+start.split('.')[0])
              .minute(+start.split('.')[1]).subtract(1, 'minutes');

            const endEnd = now
              .clone()
              .hour(+end.split('.')[0])
              .minute(+end.split('.')[1]);
            return days.includes(day) && now.isBetween(startTime, endEnd);
          }
        );
      }
    }
    return true;
  }
}
