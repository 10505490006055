import { Pipe, PipeTransform } from '@angular/core';
import { Schedule } from '../models/schedule.model';
import { SpecialtyService } from '../services/specialty.service';
import * as momentTimezone from 'moment-timezone';
import { SpecialtyConfig } from '../models/specialty-config.model';
import { objectToSchedules } from '../functions/object-to-schedules.function';

@Pipe({
  name: 'specialtyAvailableHours',
})
export class SpecialtyAvailableHoursPipe implements PipeTransform {
  constructor(private specialtyService: SpecialtyService) {}

  async transform(specialtyId: string): Promise<Schedule[]> {
    let scheduleConfiguration: SpecialtyConfig;

    scheduleConfiguration = (await this.specialtyService.getSpecialtyConfigById(
      specialtyId
    ))!;

    if (scheduleConfiguration) {
      const { availableHours } = scheduleConfiguration;
      if (Object.keys(availableHours || {}).length > 0) {
        return objectToSchedules(availableHours);
      }
    }
    return [];
  }
}
