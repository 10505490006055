import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Device } from '@opentok/client';
import { interval, Observable, Subscription, of, throwError } from 'rxjs';
import {
  delayWhen,
  finalize,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Signals } from 'src/app/enums/signals.enum';
import { CallEvent } from 'src/app/models/call-event.model';
import { Doctor } from 'src/app/models/doctor.model';
import { FileLoadResponse } from 'src/app/models/file-load-response.model';
import { FileUpload } from 'src/app/models/file-upload.model';
import { Specialty } from 'src/app/models/specialty.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CallService } from 'src/app/services/call.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { LocaleService } from 'src/app/services/locale.service';
import { OpentokService } from 'src/app/services/opentok.service';
import { PatientService } from 'src/app/services/patient.service';
import { environment } from 'src/environments/environment';
import { CallOptionsComponent } from '../call-options/call-options.component';
import { FileListComponent } from '../file-list/file-list.component';
import { FileProgressBarComponent } from '../file-progress-bar/file-progress-bar.component';

@Component({
  selector: 'app-on-call',
  templateUrl: './on-call.component.html',
  styleUrls: ['./on-call.component.css'],
})
export class OnCallComponent implements OnInit {
  microphone$ = this.opentokService.microphone$;
  camera$ = this.opentokService.camera$;
  enableFileSharing!: boolean;
  @ViewChild('callOptions')
  callOptionsComponent!: CallOptionsComponent;
  @ViewChild('fileListContainer', { read: ViewContainerRef })
  fileListViewContainerRef!: ViewContainerRef;
  filesUploading: boolean[] = [];
  filesUploaded: FileUpload[] = [];
  devices: Device[] = [];
  locale: string;

  private readonly callStartedAt = new Date();
  private readonly maxTries = 5;
  private readonly timeToHideDoctorInfo = 5 * 1000;
  private timerInterval$ = interval(this.timeToHideDoctorInfo);
  private fadeSubscription!: Subscription;
  private subscription = new Subscription();
  private connectionLost = false;
  private maxDurationMs: number;
  private maxDurationInterval$!: Observable<number>;
  private milliSecondsToValidateCall: number;
  private milliSecondsToValidateCall$!: Observable<number>;

  fadeInDoctorInfoAnimation(): void {
    this.fadeInDoctorInfo = true;
    this.fadeOutDoctorInfo = false;

    if (this.fadeSubscription) {
      this.fadeSubscription.unsubscribe();
    }
    this.subscription = this.timerInterval$.pipe(take(1)).subscribe({
      next: () => {
        this.fadeOutDoctorInfoAnimation();
      },
    });
  }

  doctor: Doctor;
  specialty: Specialty;
  fadeInDoctorInfo = true;
  fadeOutDoctorInfo = false;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private matDialog: MatDialog,
    private callService: CallService,
    private patientService: PatientService,
    private opentokService: OpentokService,
    private factoryResolver: ComponentFactoryResolver,
    public amplitudeService: AmplitudeService,
    private appointmentService: AppointmentService,
    private doctorService: DoctorService,
    private localeService: LocaleService,
  ) {
    const { doctor, call, specialty } = this.callService;
    this.doctor = doctor! || call!.doctor;
    this.specialty = doctor ? doctor.mainSpecialty : (specialty || call!.specialty);

    const { DEFAULT_CALL_TIMEOUT_MS, MIN_MS_FOR_VALID_CALL } =
      environment.callConfig;
    this.maxDurationMs = call!.maxDuration || DEFAULT_CALL_TIMEOUT_MS;
    this.maxDurationInterval$ = interval(this.maxDurationMs);

    this.milliSecondsToValidateCall = MIN_MS_FOR_VALID_CALL;
    this.milliSecondsToValidateCall$ = interval(MIN_MS_FOR_VALID_CALL);

    this.locale = this.localeService.getLocale();
  }

  async ngOnInit(): Promise<void> {
    const { call } = this.callService;
    const currentCallDetails = {callId: call?.id, doctor: call?.doctor}
    if(this.callService.specialty){
      this.amplitudeService.showEvent(AmplitudeEvents.CALL_CREATED_BY_SPECIALTY, currentCallDetails);
    }
    this.subscription.add(
      this.opentokService.event$.subscribe({
        next: (event: CallEvent) => {
          if (event.signal === Signals.CALL_ENDED) {
            this.connectionLost = !!event.data.connectionLost;
            this.endCall();
          }
          else if (event.signal === Signals.DOCTOR_ID) {
            var doctorId = event.data.id;
            if (!this.callService.callData.doctor && doctorId){
              this.ngZone.run(async () => {
                var doctorFromRequest = await this.doctorService.getDoctor(doctorId)
                this.doctor = doctorFromRequest;
                this.callService.doctor = doctorFromRequest;
                window.top.postMessage({ type: 'doctor_connected' }, '*');
              });
            }
          }
        },
      })
    );
    this.subscription.add(
      this.milliSecondsToValidateCall$.pipe(take(1)).subscribe({
        next: async () => {
          await this.callService.validateCall();
          console.log(
            `Call validated after ${this.milliSecondsToValidateCall / 1000}s`
          );
        },
      })
    );
    this.subscription.add(
      this.maxDurationInterval$.pipe(take(1)).subscribe({
        next: () => {
          console.log(`Call ended after ${this.maxDurationMs / 1000}s`);
          this.endCall();
        },
      })
    );
    this.subscription.add(
      this.opentokService.devices$.subscribe({
        next: (devices: Device[]) => {
          if(devices) this.devices = devices;
        }
      })
    )

    this.enableFileSharing = !!(await this.patientService.getProvider())
      .fileSharing;
  }

  fadeOutDoctorInfoAnimation(): void {
    this.fadeInDoctorInfo = false;
    this.fadeOutDoctorInfo = true;
  }

  uploadFile(file: File): void {
    const formData = new FormData();
    formData.append('callFile', file);

    const fileUpload: FileUpload = {
      file,
      loaded: 0,
      loading: false
    };

    const componentRef = this.componentFactoryResolver(fileUpload);
    componentRef.instance.selfComponentRef = componentRef;

    const localeSubscription = new Subscription();
    let retryCount = 0;
    const uploadFileSubscription = this.callService
      .uploadFile(formData)
      .pipe(
        // Retry when the call is not created
        retryWhen((errors: Observable<HttpErrorResponse>) => {
          return errors.pipe(
            switchMap((error: HttpErrorResponse) => {
              if (retryCount < this.maxTries) {
                return of(error);
              }
              return throwError(error);
            }),
            // Wait for 30 seconds
            delayWhen(
              ({ error, message }: HttpErrorResponse) => {
                const errorMessage = error.message ? error.message : message;
                console.error(errorMessage, { error });
                componentRef.instance.message = errorMessage;
                return componentRef.instance.retryUpload.asObservable().pipe(
                  take(1),
                  tap(() => {
                    retryCount++;
                    componentRef.instance.message = undefined;
                  })
                );
              }
            ),
            tap(() =>
              console.info(`Retrying upload... Try number: ${retryCount}`)
            ),
            finalize(() => {
              componentRef.instance.destroy(() => {
                console.error(`Upload failed after ${retryCount} retries`);
                localeSubscription.unsubscribe();
                this.callOptionsComponent.clearInputFile();
              });
            })
          );
        }),
        tap((event: HttpEvent<FileLoadResponse>) => {
          if (event.type === HttpEventType.UploadProgress) {
            fileUpload.loaded = event.loaded;
          }
        })
      )
      .subscribe({
        next: (event: HttpEvent<FileLoadResponse>) => {
          switch (event.type) {
            case HttpEventType.Response:
              this.filesUploaded = [...this.filesUploaded, fileUpload];
              this.opentokService.sendSignal(
                Signals.FILE_UPLOADED,
                JSON.stringify(event.body)
              );
              break;
            case HttpEventType.Sent:
              localeSubscription.add(
                componentRef.instance.cancelUpload.asObservable().subscribe({
                  next: () => {
                    componentRef.destroy();
                    localeSubscription.unsubscribe();
                    uploadFileSubscription.unsubscribe();
                  },
                })
              );
              break;
          }
        },
        complete: () => {
          componentRef.instance.destroy(() => {
            console.info(`File uploaded successfully`);
            localeSubscription.unsubscribe();
            this.callOptionsComponent.clearInputFile();
          });
        },
      });
    this.subscription.add(uploadFileSubscription);
  }

  componentFactoryResolver(fileUpload: FileUpload): ComponentRef<FileProgressBarComponent> {
    const factory = this.factoryResolver.resolveComponentFactory(
      FileProgressBarComponent
    );
    const componentRef =
      this.fileListViewContainerRef.createComponent<FileProgressBarComponent>(
        factory
      );
    componentRef.instance.fileUpload = fileUpload;
    return componentRef;
  }

  endCall(): void {
    this.amplitudeService.showEvent(AmplitudeEvents.CALL_DISCONNECTED_BY_USER, {})
    this.subscription.unsubscribe();
    const callDurationInSeconds =
      (new Date().getTime() - this.callStartedAt.getTime()) / 1000;
    this.callService
      .endCall(callDurationInSeconds, this.connectionLost)
      .then(() => {
        this.opentokService.disconnect();
        this.ngZone.run(() => {
          if(this.appointmentService.inAppointment()) {
            this.appointmentService.setAppointmentStatus('ended');
          }
          window.top.postMessage({ type: 'call_ended' }, '*');
          this.router.navigate([`/${AppPaths.CALL}`, AppPaths.CALL_RATE])
        });
      });
  }

  showLoadedFiles(): void {
    this.matDialog.open(FileListComponent, {
      panelClass: 'custom-dialog-container',
      width: '90%',
      maxWidth: '350px',
      data: this.filesUploaded,
      hasBackdrop: false
    });
  }

  toggleMicrophone(): void {
    const microphone = this.opentokService.toggleMicrophone();
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_TOGGLE_MICROPHONE, { microphone: microphone })
  }

  toggleCamera(): void {
    const camera = this.opentokService.toggleCamera();
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_TOGGLE_CAMERA, { camera: camera})
  }

  switchCamera(): void {
    const deviceId = this.opentokService.switchCamera();
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_SWITCH_CAMERA, { deviceId: deviceId })
  }
}
