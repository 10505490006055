<div class="mat-dialog-header">
  <h1 mat-dialog-title>
    {{ 'doctor_schedules_title' | locale }}
    {{ doctor?.firstName }}
    {{ doctor.lastName }}
  </h1>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="(doctor.workingHours | doctorWorkingHours) as workingHours">
    <app-schedules
      [schedules]="workingHours"
      [areDoctorSchedules]="true">
    </app-schedules>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button
    color="primary"
    mat-dialog-close
    mat-stroked-button>
    {{ 'dialog_close' | locale }}
  </button>
</div>
