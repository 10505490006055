<app-header></app-header>

<!-- routeroutlet (rosa) -->
<div class="router">
  <app-stepper
    [numberOfSteps]="steps"
    [currentStepIndex]="currentStepIndex">
  </app-stepper>

  <app-nav-content [backgroundYPositionSm]="'3rem'">
    <ng-container ngProjectAs="[title]">
      <app-stepper
        [numberOfSteps]="steps"
        [currentStepIndex]="currentStepIndex">
      </app-stepper>
      <h2 class="patient">
        {{ 'reason_patient' | locale }}
        <span class="patient-name">
          {{ patient?.name}}
        </span>
      </h2>
      <h3 class="message" *ngIf="doctor && !specialty">
        {{ 'call_suggestion_doctor_title' | locale}}
      </h3>
      <h3 class="message" *ngIf="specialty && !doctor">
        {{ 'call_suggestion_title' | locale}}
      </h3>
      <p class="specialty" *ngIf="specialty">
        {{ specialty | localizedName: locale }}
      </p>
      <p class="doctor" *ngIf="doctor">
        {{ doctor.lastName }}, {{ doctor.firstName }}
      </p>

    </ng-container>
    <ng-container ngProjectAs="[card]">
      <p class="suggestion-text">{{ "call_suggestions" | locale }}</p>
      <div class="cards-container">
        <div class="card accent">
          <mat-icon svgIcon="phone"></mat-icon>
          <p>{{ "call_suggestion_connection" | locale }}</p>
        </div>
        <div class="card dark">
          <mat-icon svgIcon="media"></mat-icon>
          <p>{{ "call_suggestion_media" | locale }}</p>
        </div>
        <div class="card primary">
          <mat-icon svgIcon="chair"></mat-icon>
          <p>{{ "call_suggestion_no_walk" | locale }}</p>
        </div>
        <div class="card gray headphones">
          <mat-icon svgIcon="headphones"></mat-icon>
          <p>{{ "call_suggestion_headphones" | locale }}</p>
        </div>
        <div class="card purple noise">
          <mat-icon svgIcon="noise"></mat-icon>
          <p>{{ "call_suggestion_noise" | locale }}</p>
        </div>
        <div class="card accent notification">
          <mat-icon svgIcon="sound_off"></mat-icon>
          <p>{{ "call_suggestion_notifications_off" | locale }}</p>
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="goToCall()">
        {{ "call_suggestion_button" | locale}}
        <mat-icon svgIcon="east" class="east"></mat-icon>
      </button>
    </ng-container>
  </app-nav-content>
</div>
