<ng-container *ngIf="!isSelectable; else selectionList">
  <mat-list color="primary">
    <mat-list-item
      [ngClass]="{ titular: member.owner === true }"
      *ngFor="let member of members; trackBy: trackById">
      <img mat-list-avatar *ngIf="member.image; else notImage" [src]="member.image">
      <p mat-line>
        {{ member.name }}
      </p>
    </mat-list-item>
  </mat-list>
</ng-container>
<ng-template #selectionList>
  <mat-selection-list color="primary" (selectionChange)="selectionChange($event)" #membersList>
    <mat-list-option
      [value]="member"
      [selected]="selectedMember?.id === member.id"
      *ngFor="let member of members; trackBy: trackById">
      <img mat-list-avatar *ngIf="member.image; else notImage" [src]="member.image">
      <p mat-line>
        {{member.name}}
      </p>
    </mat-list-option>
  </mat-selection-list>
</ng-template>
<ng-template #notImage>
  <mat-icon mat-list-icon svgIcon="user"></mat-icon>
</ng-template>
