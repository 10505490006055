import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { IntegrationType } from 'src/app/enums/integration-type.enum';

export class errorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-auth0-email-login',
  templateUrl: './auth0-email-login.component.html',
  styleUrls: ['./auth0-email-login.component.css']
})
export class Auth0EmailLoginComponent implements OnInit {

  loginFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })
  failedLoginErrorMessage: string = '';
  auth0: any;
  hidePassword = true;

  matcher = new errorStateMatcher();

  constructor(
    private router:Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService,
  ) { }

  ngOnInit(): void {}

  login() {
    var self = this;
    this.failedLoginErrorMessage = '';
    if (this.loginFormGroup.invalid) return;
    this.loadingService.toggleSpinner();
    this.auth0.client.login({
      realm: environment.AUTH0_REALM,
      username: this.loginFormGroup.value.email,
      password: this.loginFormGroup.value.password,
      scope: environment.AUTH0_SCOPE
    },
      async function (err: { code: string; }, authResult: any) {
        if (err) {
          if (err.code == "invalid_grant")
            self.failedLoginErrorMessage = "wrong_mail_or_password"
          else
            self.failedLoginErrorMessage = "login_error"
          self.loadingService.toggleSpinner();
          return;
        }
        try {
          await self.authService.handleAuth0Login(authResult.idToken);
          self.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
          self.loadingService.toggleSpinner();
        } catch (err:any) {
          if (err.status == 409) {
            const extra = err.error.extra;
            const integrationType = extra.provider.integrationType;
            const { EXCEL_AFFILIATE_SERVICE, PROVIDER_TOKEN_SERVICE } = IntegrationType;
            
            if(integrationType !== EXCEL_AFFILIATE_SERVICE && integrationType !== PROVIDER_TOKEN_SERVICE) {
              self.authService.setItem("id_token", authResult.idToken)
              self.router.navigate([AppPaths.CREDENTIAL_VALIDATION])
            } else {
              const message = extra.isSubscriber ? 'user_inactive_subscriber' : 'user_inactive_not_subscriber';
              self.showInactiveUserDialog(message);
            }
          }
          else self.showNoUserDialog()
          self.loadingService.toggleSpinner();
        }
      }
    );
  }

  showNoUserDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_login_error_no_user",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  showInactiveUserDialog(message: string){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: message,
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

}
