import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root'
})
export class Cie10Service {

    constructor(private requestService: RequestService) { }

    getCie10Description(code:string){
        return this.requestService.get(`/cie10/${code}`)
    }
}


