import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Call } from 'src/app/models/call.model';
import { PatientMedicalRecord } from 'src/app/models/patient-medical-record.model';
import { CallService } from 'src/app/services/call.service';
import { Cie10Service } from 'src/app/services/cie10.service';
import { LocaleService } from 'src/app/services/locale.service';
import { MedicalHistoryCategoryService } from 'src/app/services/medical-history-category.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-call-detail',
  templateUrl: './call-detail.component.html',
  styleUrls: ['./call-detail.component.css'],
  providers: [MedicalHistoryCategoryService]
})
export class CallDetailComponent implements OnInit, OnDestroy {

  call:Call | undefined;
  resolution:string = '';
  diagnosis:string = '';
  locale:string = '';
  medicalRecords:PatientMedicalRecord[] |undefined;
  downloadPath:string = `/${AppPaths.NAVBAR}/${AppPaths.DOWNLOAD}/`

  constructor(
    private route: ActivatedRoute,
    private localeService:LocaleService,
    private callService:CallService,
    private cie10Service:Cie10Service,
    private navigationService: NavigationService,
    private medicalHistoryService:MedicalHistoryCategoryService
  ) {
    this.navigationService.hideMenu();
  }

  ngOnInit(): void {
    this.locale = this.localeService.getLocale();
    this.route.params.subscribe(async params => {
      if(params.id){
        this.call = await this.callService.getCall(params.id);
        this.medicalRecords = await this.medicalHistoryService.getMedicalRecordsForPatient(this.call.caller.id)
        this.getDiagnosesByCode()
        this.getResolution()
      }
    })
  }

  ngOnDestroy(): void {
    this.navigationService.showMenu();
  }

  async getDiagnosesByCode(){
    if(this.call){
      let diagnosis = await this.cie10Service.getCie10Description(this.call?.diagnosis.diagnosis)
      this.diagnosis = diagnosis.description
    }
  }

  getResolution() {
    if(this.call?.diagnosis.solved) this.resolution = "call_detail_solved"
    else if(this.call?.diagnosis.referred) this.resolution = "call_detail_referred"
    else if(this.call?.diagnosis.connectionFailed) this.resolution = "call_detail_connection_failed"
    else if(this.call?.diagnosis.urgent) this.resolution = "call_detail_urgent"
    else if(this.call?.diagnosis.wrongPhone) this.resolution = "call_detail_wrong_phone"
    else if(this.call?.diagnosis.patientDischarged) this.resolution = "call_detail_patient_discharged"
    else if(this.call?.diagnosis.discontinueMonitoring) this.resolution = "call_detail_discontinue_monitoring"
    else if(this.call?.diagnosis.couldNotContact) this.resolution = "call_detail_could_not_contact"
    else if(this.call?.diagnosis.consultationPreviouslySolved) this.resolution = "call_detail_consultation_previously_solved"
  }

  getRecordLocalized(record:PatientMedicalRecord){
    switch (this.localeService.getLocale()) {
      case 'pt':
      case 'pt-br':
        return record.localizedName['pt'];
      case 'es-co':
        return record.localizedName['es'];
      case 'en':
      case 'en-us':
        return record.localizedName['en'];
      default:
        return record.localizedName['es'];
    }
  }
}
