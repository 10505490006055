import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { LocalePipe } from 'src/app/locale.pipe';

@Directive({
  selector: '[fileUpload]',
  providers: [LocalePipe],
})
export class FileUploadDirective implements AfterViewInit {
  @Output() fileToUpload = new EventEmitter<File>();

  private readonly typesToAccept =
    '.doc, .docx, application/msword, application/vnd.ms-excel, image/*, .pdf, .xls, .xlsx';
  private readonly allowedExtensions =
    /(.doc|.docx|.pdf|.jpg|.jpeg|.png|.xls|.xlsx|msword|ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.ms-excel)$/i;
  private inputFile!: HTMLInputElement;

  constructor(
    private renderer: Renderer2,
    private localePipe: LocalePipe,
    private viewContainerRef: ViewContainerRef
  ) { }

  @HostListener('click')
  onClick() {
    this.inputFile.click();
  }

  ngAfterViewInit(): void {
    this.insertInputInDom();
  }

  insertInputInDom(): void {
    this.inputFile = this.renderer.createElement('input');
    this.inputFile.type = 'file';
    this.inputFile.accept = this.typesToAccept;
    this.inputFile.style.display = 'none';
    this.fileUpload();
    const element = this.viewContainerRef.element.nativeElement;
    const parentContainer = this.renderer.parentNode(element);
    parentContainer.appendChild(this.inputFile);
  }

  fileUpload(): void {
    const fileUpload = this.inputFile!;
    fileUpload.onchange = () => {
      const file = fileUpload.files![0];
      if (file) {
        if (!this.allowedExtensions.test(file.type)) {
          alert(this.localePipe.transform('file_type_not_allowed'));
          fileUpload.value = '';
        } else {
          this.fileToUpload.emit(file);
        }
      }
    };
  }

  clearInputFile(): void {
    this.inputFile.value = '';
  }
}
