<div class="item-container">
    <div class="item-icon">
        <div class="icon-background" [ngStyle]="{'background-color':color}">
            <img *ngIf="srcIcon" [src]="srcIcon" [alt]="itemText">
            <mat-icon *ngIf="!srcIcon && iconName" [svgIcon]="iconName" [ngStyle]="{'color':'white'}"></mat-icon>
        </div>
    </div>
    <div class="item-text-container">
        <h4 class="item-text">{{itemText}}</h4>
        <h4 class="item-subtext" *ngIf="itemSubtext">{{itemSubtext}}</h4>
    </div>
    <div class="item-arrow">
        <mat-icon svgIcon="arrow" [ngClass]="{'south':south}" [ngStyle]="{'color': !south ? color : 'black'}"></mat-icon>
    </div>
</div>
