import { Injectable } from '@angular/core';
import { HealthInsurance } from '../models/healt-insurance.model';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class HealthInsuranceService {

  constructor(private requestService: RequestService) { }

  getHealthInsurances(): Promise<HealthInsurance[]> {
    return this.requestService.get('/healthInsurances');
  }
}
