import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Doctor } from 'src/app/models/doctor.model';
import { Specialty } from 'src/app/models/specialty.model';
import { LocaleService } from 'src/app/services/locale.service';
import { DoctorSchedulesComponent } from '../doctor-schedules/doctor-schedules.component';

@Component({
  selector: 'app-doctor-info',
  templateUrl: './doctor-info.component.html',
  styleUrls: ['./doctor-info.component.css']
})
export class DoctorInfoComponent implements OnInit {
  @Input() doctor!: Doctor;
  @Input() specialty!: Specialty;
  @Input() showSchedules = false;
  isAlwaysAvailable = false;
  defaultImage = 'assets/doctor_default.png';
  locale: string;

  constructor(
    private matDialog: MatDialog,
    private localeService: LocaleService,
  ) {
    this.locale = this.localeService.getLocale();
   }

  ngOnInit(): void {
    if (this.doctor.workingHours.hasOwnProperty('00.00-23.59') && this.doctor.workingHours['00.00-23.59'].length === 7) {
      this.isAlwaysAvailable = true;
    }
  }

  openDoctorSchedulesDialog(): void {
    this.matDialog.open(DoctorSchedulesComponent, {
      data: this.doctor,
      width: '90%',
      maxWidth: '400px',
      panelClass: 'custom-dialog-container'
    });
  }

}
