import { Location } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Howl } from 'howler';
import { interval, Observable, PartialObserver, Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';

import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Signals } from 'src/app/enums/signals.enum';
import { CallEvent } from 'src/app/models/call-event.model';
import { Call } from 'src/app/models/call.model';
import { Doctor } from 'src/app/models/doctor.model';
import { Specialty } from 'src/app/models/specialty.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CallService } from 'src/app/services/call.service';
import { LocaleService } from 'src/app/services/locale.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { OpentokService } from 'src/app/services/opentok.service';
import { PatientService } from 'src/app/services/patient.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { CallCancelationWarningComponent } from '../call-cancelation-warning/call-cancelation-warning.component';
import { DoctorNotAvailableComponent } from '../doctor-not-available/doctor-not-available.component';
import { WebSocketsService } from 'src/app/services/web-sockets.service';

@Component({
  selector: 'app-call-waiting',
  templateUrl: './call-waiting.component.html',
  styleUrls: ['./call-waiting.component.css'],
})
export class CallWaitingComponent implements OnInit, OnDestroy {
  specialty!: Specialty;
  doctor?: Doctor;
  waitingSound: Howl;
  microphone$ = this.opentokService.microphone$;
  camera$ = this.opentokService.camera$;
  private subscription = new Subscription();
  private timeoutMilliseconds!: number;
  private timeToRetryInMilliseconds = 60 * 1000;
  locale: string;
  private pingInterval : number = 0;
  private wsPingInterval : number = 0;

  whiteLabel: boolean;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private location: Location,
    private matDialog: MatDialog,
    private callService: CallService,
    private localeService: LocaleService,
    private opentokService: OpentokService,
    private patientService: PatientService,
    private amplitudeService: AmplitudeService,
    private navigationService: NavigationService,
    private webSocketsService: WebSocketsService,
    private appointmentService: AppointmentService,
    private registrationService: RegistrationService,
  ) {
    this.waitingSound = new Howl({
      src: [`/assets/sounds/waiting.mp3`],
      loop: true,
    });
    this.locale = this.localeService.getLocale();
    this.whiteLabel = this.registrationService.whiteLabel;
  }

  async ngOnInit(): Promise<void> {
    const self = this;
    this.specialty = this.callService.callData.specialty;
    this.doctor = this.callService.callData.doctor || undefined;

    const { callTimeoutMilliseconds, infiniteCallTimeoutMilliseconds, name } =
      await this.patientService.getProvider();
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_CALLING, {
      provider: name,
    });

    this.timeoutMilliseconds = this.callService.call?.doctor
      ? ( callTimeoutMilliseconds || 60000 )
      : ( infiniteCallTimeoutMilliseconds || 50000 );

    this.createCall();
    this.subscribeToCallEvent();

    this.navigationService.hideBackButton();
    this.navigationService.hideOptions();
    this.webSocketsService.connect(
    /*onConnect*/() => {
      self.webSocketsService.ping();
      self.wsPingInterval = setInterval(() => {
        self.webSocketsService.ping();
      }, 5000);
      self.resetPingInterval();
    },
    /*onDisconnect*/() => {
      self.resetWsPingInterval();
      if (0 == self.pingInterval && self.webSocketsService.isConnecting()) {
        self.patientService.ping();
        self.pingInterval = setInterval(() => {
          if (self.webSocketsService.isConnecting()) {
            self.patientService.ping();
          } else {
            self.resetPingInterval();
          }
        }, 5000);
      }
    })
    this.waitingSound.play();
  }

  resetPingInterval() : void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = 0;
    }
  }

  resetWsPingInterval() : void {
    if (this.wsPingInterval) {
      clearInterval(this.wsPingInterval);
      this.wsPingInterval = 0;
    }
  }

  ngOnDestroy(): void {
    this.resetPingInterval();
    this.resetWsPingInterval();
    this.navigationService.showBackButton();
    this.navigationService.showOptions();
    this.waitingSound.stop();
    this.subscription.unsubscribe();
    this.webSocketsService.disconnect();
  }

  createCall(): void {
    const initCall$ = this.callService
      .createCall(this.localeService.getLocaleIdInStorage())
      .pipe(
        // Initialize the call with the opentok session
        tap<Call>(async (call: Call) => {
          await this.opentokService.initializeSession(
            call.openTokApiKey,
            call.session,
            call.token
          );
        }),
        // Wait for the doctor to accept the call
        switchMap<Call, Observable<number>>(() =>
          interval(this.timeoutMilliseconds)
        ),
        take(1)
      );

    const initCallObserver: PartialObserver<number> = {
      next: async () => {
        // If the call is not accepted, cancel it
        if ( this.callService.doctor || this.appointmentService.inAppointment() ) {
          console.log('Call ended by timeout.');
          this.amplitudeService.showEvent(
            AmplitudeEvents.CALL_DISCONNECTED_BY_TIMEOUT,
            {}
          );
          await this.callService.timeoutCall();
          this.opentokService.disconnect();
          this.waitingSound.stop();
          if(this.appointmentService.inAppointment()) this.appointmentService.setAppointmentStatus('notAvailableDoctor');
          const dialogRef = this.matDialog.open(DoctorNotAvailableComponent, {
            panelClass: 'custom-dialog-container',
            maxWidth: 350,
          });
          dialogRef.beforeClosed().subscribe({
            next: () => {
              this.location.back();
            },
          });
        }
      },
    };

    this.subscription.add(initCall$.subscribe(initCallObserver));
  }

  cancelCall(): void {
    const dialogRef = this.matDialog.open(CallCancelationWarningComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: '350px',
      disableClose: true,
    });

    dialogRef.beforeClosed().subscribe({
      next: (confirmation: boolean) => {
        if (confirmation) {
          this.waitingSound.stop();
          if (this.callService.call) {
            this.callService.cancelCall().then(() => {
              this.opentokService.disconnect();
              this.location.back();
            });
          } else {
            this.location.back();
          }
        }
      },
    });
  }

  toggleMicrophone(): void {
    this.opentokService.toggleMicrophone();
  }

  toggleCamera(): void {
    this.opentokService.toggleCamera();
  }

  private subscribeToCallEvent() {
    this.subscription.add(
      this.opentokService.event$.subscribe({
        next: (event: CallEvent) => {
          if (event.signal === Signals.CALL_STARTED) {
            this.ngZone.run(() =>
              this.router.navigate([`/${AppPaths.CALL}`, AppPaths.ON_CALL])
            );
          }
        },
      })
    );
  }
}
