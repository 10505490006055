import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CountryISO } from 'ngx-intl-tel-input';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  translations: any;
	locale!: string;
	defaultLocale: string = 'es-ar';
	supportedLocales = ['es-ar', 'es', 'es-co', 'pt-br', 'pt', 'en', 'en-us'];
	providerLanguages: any;

	constructor(private http: HttpClient) { }

	use(locale: string): Promise<{}> {
		return new Promise<{}>((resolve, reject) => {
		  const langPath = `assets/i18n/${locale}.json`;
		  this.http.get<{}>(langPath).subscribe(
			translation => {
			  this.translations = Object.assign({}, translation || {});
			  resolve(this.translations);
			},
			error => {
			  this.translations = {};
			  resolve(this.translations);
			}
		  );
		});
	}

	getString(key:string):string {
		return this.translations[key];
	}

	getLocale(){
		return this.locale || this.defaultLocale;
	}

	setLocale(locale: string) {
		this.locale = locale
	}

	setProviderLanguage(provider: any){
		this.providerLanguages = provider.supportedLanguages;
        if(_.isEmpty(provider.supportedLanguages)){
			this.setLocaleInStorage(provider.language, "session");
        } else if(provider.supportedLanguages.length == 1) {
        	this.setLocaleInStorage(provider.supportedLanguages[0].code, "local");
        }
	}

	getProviderSupportedLanguages(){
		return this.providerLanguages;
	}

	setLocaleInStorage(locale: string, storage: string) {
		locale = Array.isArray(locale)? this.getSupportedLocaleFromArray(locale): locale;
		if (this.supportedLocales.includes(locale)){
		  this.locale = locale;
		} else {
		  console.log(locale + " not supported, using default: " + this.defaultLocale);
		  this.locale = this.defaultLocale;
		}

		switch(storage) {
		  case "session":
			sessionStorage.setItem('locale', this.locale);
			break;
		  case "local":
			localStorage.setItem('locale', this.locale);
			break;
		}

		return this.use(this.locale);
	}

	getSupportedLocaleFromArray(locales: any[]){
		var i = 0;
		var locale = this.defaultLocale;
		while(i<locales.length) {
		  let localeShort = locales[i].toLowerCase().split('-')[0];
		  if(this.supportedLocales.includes(locales[i].toLowerCase())) {
			locale = locales[i].toLowerCase()
			break;
		  } else if (this.supportedLocales.includes(localeShort)) {
		    locale = localeShort;
		    break;
		  }
		  i++;
		}
		return locale;
	}

	setLocaleIdInStorage(id: string) {
		return localStorage.setItem("localeId", id);
	}

	getLocaleIdInStorage() {
		return localStorage.getItem("localeId");
	}

	getLocaleFromLocalStorage() {
		return localStorage.getItem('locale')
	}

	getLocaleFromSessionStorage() {
		return sessionStorage.getItem('locale')
	}

	public getHeaderLocale(): string {
		//We send only language headers thar are accepted by the API
		//That is because we use sails i18n to translate messages, to see documentation, visit https://0.12.sailsjs.com/documentation/concepts/internationalization
		switch (this.locale) {
		  case 'pt-br':
		  case 'pt':
			return 'pt-BR';
		  case 'es-co':
			return 'es-CO';
		  case 'en':
		  case 'en-us':
			return 'en-US'
		  default:
			return 'es-AR';
		}
	}

	public getLanguageISO(): string {
		const locale = this.getLocaleFromLocalStorage() ?? this.getLocaleFromSessionStorage() ?? this.defaultLocale;
		return locale.split("-")[0];
	}

  getCountryISO(language: string =  this.getLocale()): CountryISO {
    switch (language) {
      case "es-co":
        return CountryISO.Colombia;
      case "en":
      case "en-us":
        return CountryISO.UnitedStates;
      case "pt":
      case "pt-br":
        return CountryISO.Brazil;
      default:
        return CountryISO.Argentina;
    }
  }
}
