<app-header></app-header>
<app-nav-content>
  <ng-container ngProjectAs="[title]">
  </ng-container>
  <ng-container ngProjectAs="[card]">
    <div class="message-container">
      <h1>
        {{ messageTitle | locale }}
      </h1>
      <p>
        {{ messageBody1 | locale }} <br>
        {{ messageBody2 | locale }}
      </p>
      <mat-icon svgIcon="heart"></mat-icon>
    </div>
    <button
      *ngIf="!inAppointment && !isShortFlow"
      mat-flat-button
      color="primary"
      (click)="goToHome()">
      {{ 'call_ended_go_to_home' | locale}}
      <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>
  </ng-container>
</app-nav-content>
