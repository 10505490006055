<mat-icon svgIcon="lad"></mat-icon>
<div class="container">
  <div class="title">
    <img class="company-logo" *ngIf="provider.image" [src]="provider.image" alt="Provider logo">
  </div>
  <div class="card">
    <div class="message-container">
      <p>
        {{ provider.loginValidatorTextHeader || "registration_without_text" | locale }}
      </p>
      <mat-icon svgIcon="apron"></mat-icon>
      <mat-icon svgIcon="heart"></mat-icon>
    </div>
    <a
      *ngIf="redirectURI && !canOpenApp; else openApp"
      color="primary"
      target="_blank"
      mat-flat-button
      [href]="redirectURI | safeURL"
      [disabled]="!redirectURI">
      {{ "registration_go_to" | locale }} {{ provider.name }}
      <mat-icon svgIcon="east" class="east"></mat-icon>
    </a>
    <ng-template #openApp>
      <a
        *ngIf="redirectURI"
        color="primary"
        target="_blank"
        mat-flat-button
        (click)="onClickOpenApp()">
        {{ "registration_go_to" | locale }} {{ provider.name }}
        <mat-icon svgIcon="east" class="east"></mat-icon>
     </a>
    </ng-template>
  </div>
</div>
