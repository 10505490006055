<app-header></app-header>
<ng-container *ngIf="doctor">
    <app-doctor-info
      class="m0-auto"
      [doctor]="doctor"
      [showSchedules]="true"
      [specialty]="doctor.mainSpecialty">
    </app-doctor-info>
    <ng-container *ngIf="!inAppointment">
      <button
          mat-flat-button
          class="m0-auto dark-button"
          (click)="isFavorite ? removeFavoriteDoctor($event): addFavoriteDoctor($event)">
          {{ "doctor_detail_add_favorite_button" | locale}}
          <mat-icon
            [svgIcon]="isFavorite ? 'fav-heart-filled' : 'fav-heart-outline'">
          </mat-icon>
        </button>
    </ng-container>
    <button
      (click)="call()"
      class="m0-auto call-button"
      mat-flat-button color="primary"
      [disabled]="!doctor.available || !doctor.canCall || !doctor.hasDevice || doctor.banned || doctor.doNotDisturb">
        {{ "continue" | locale}}
        <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>

</ng-container>
