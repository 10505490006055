import { Pipe, PipeTransform } from '@angular/core';
import { Specialty } from '../models/specialty.model';
import { LocaleService } from '../services/locale.service';

@Pipe({
  name: 'localizedName',
})
export class LocalizedNamePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}

  transform(
    value: Specialty,
    locale: string = this.localeService.defaultLocale
  ): string | undefined {
    if (typeof value === 'string' || !value) {
      return undefined;
    }
    let localizedName = value.localizedName[locale];
    if (!localizedName) {
      for (const [key] of Object.entries(value.localizedName)) {
        if (key.includes(locale)) {
          localizedName = value.localizedName[key];
        }
      }
    }
    // Adding spaces to break the line with CSS
    return !localizedName ? '' : localizedName.replace('/', ' / ');
  }
}
