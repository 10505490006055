import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[backButton]'
})
export class BackButtonDirective {
  @HostBinding('class.material-icons-rounded') backButtonClass = true;

  constructor() { }

  @HostListener('click')
  goToBack() {
    window.history.back();
  }

}
