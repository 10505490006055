<div class="mat-dialog-header">
  <h1 mat-dialog-title>{{ "call_warning_title" | locale }}</h1>
  <mat-icon svgIcon="heart"></mat-icon>
</div>
<p mat-dialog-content>
  {{ "call_warning_message" | locale }}
</p>
<div mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false">
    {{"dialog_close" | locale }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true">
    {{ "call_warning_button" | locale}}
    <mat-icon class="east">east</mat-icon>
  </button>
</div>
