import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css'],
})
export class PasswordResetComponent implements OnInit {
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {}

  resetPassword() {
    if (this.emailControl.valid) {
      this.spinnerService.toggleSpinner();
      this.authService
        .requestPasswordReset(this.emailControl.value)
        .then(() => {
          this.spinnerService.toggleSpinner();
          this.router.navigate([`/${AppPaths.SOCIAL}`, AppPaths.REQUEST_PASSWORD_SENT], {
            queryParams: { email: this.emailControl.value },
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }
}
