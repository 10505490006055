<div class="footer">
    <div class="logo-social">
        <div class="logo">
            <app-logo></app-logo>
        </div>
        <div class="social">
            <a href="https://ar.linkedin.com/company/llamando-al-doctor" target="_blank">
                <mat-icon svgIcon="linkedin"></mat-icon>
            </a>
            <a href="https://www.instagram.com/llamandoaldr/" target="_blank">
                <mat-icon svgIcon="instagram"></mat-icon>
            </a>
            <a href="https://es-la.facebook.com/llamandoaldoctor/" target="_blank">
                <mat-icon svgIcon="facebook"></mat-icon>
            </a>
        </div>

    </div>
    <div class="signature">
        <div class="signature-year">
            <span>© {{currentYear}} - Av. Córdoba 1776. CABA - C1055AAU</span>
        </div>
        <div class="app-store">
            <div>
                <a href="https://play.google.com/store/apps/details?id=com.llamandoaldoctor&hl=es_419&gl=US"
                    target="_blank">
                    <mat-icon svgIcon="play_store" alt="google store logo" class="store-logo"></mat-icon>
                </a>
                <a href="https://apps.apple.com/ar/app/llamando-al-doctor/id1196903602" target="_blank">
                    <mat-icon svgIcon="app_store" alt="apple store logo" class="store-logo"></mat-icon>
                </a>
            </div>

        </div>
    </div>
</div>
<div class="build-number" *ngIf="buildNumber">Build {{buildNumber}}</div>