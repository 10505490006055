import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AuthService } from 'src/app/services/auth.service';
import { PatientService } from 'src/app/services/patient.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';

@Component({
  selector: 'app-setting-options',
  templateUrl: './setting-options.component.html',
  styleUrls: ['./setting-options.component.css']
})
export class SettingOptionsComponent implements OnInit {
  quantityOfMembers: number = 0;

  readonly settingOptions = [
    {
      name: 'settings_my_account',
      icon: 'user',
      background: 'bg-primary',
      linkTo: [AppPaths.ACCOUNT]
    },
    {
      name: 'settings_t&c',
      icon: 'info',
      background: 'bg-dark',
      linkTo: [`/${AppPaths.TERMS_AND_CONDITIONS}`,{readOnly:"true"}]
    }
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
  ) { }

  ngOnInit(): void {
    this.patientService.getFamilyGroup().then((familyGroup: FamilyGroup) => {
      this.quantityOfMembers =  familyGroup.members.length;
      if (this.quantityOfMembers > 1) {
        this.settingOptions.splice(1, 0, {
          name: 'settings_family_group',
          icon: 'users',
          background: 'bg-accent',
          linkTo: [AppPaths.FAMILY_GROUP]
        });
      }
    })
  }

  logout() {
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_LOGOUT,{})
    this.authService.logout();
    this.router.navigate([`/${AppPaths.AUTH}`]);
  }

}
