import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/models/provider.model';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-validator-explanation',
  templateUrl: './validator-explanation.component.html',
  styleUrls: ['./validator-explanation.component.css']
})
export class ValidatorExplanationComponent implements OnInit {
  provider: Provider | undefined;
  customImage: string = "../assets/default-credential.png";

  constructor(
    private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.provider = this.registrationService.getProvider();
  }

}
