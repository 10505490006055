<app-sticker *ngIf="!isNativePlatform"
    [title]="'registration_sticker_title' | locale"
    [description]="'registration_sticker_description' | locale">
</app-sticker>
<app-stepper [numberOfSteps]="4" [currentStepIndex]="1"></app-stepper>
<app-logo *ngIf="!whiteLabel" class="bg-dark"></app-logo>

<div class="img-container">
    <img *ngIf="provider" class="providerImage" [src]="provider.header ? provider.header : provider.registrationSelectorImage">
</div>
<p class="validationText"> {{"registration_second_step_title" | locale }} {{(provider.validatorName || "validation_reference") | locale | lowercase}}</p>
<div class="loginForm">
    <form class="loginInputs" [formGroup]="validationFormGroup">
        <mat-label>{{"registration_second_step_ID" | locale}}</mat-label>
        <mat-form-field appearance="outline">
            <div class="validationInput">
                <input matInput
                    type="number"
                    formControlName="documentNumber"
                    placeholder="12677265"
                    autocomplete="off">
            </div>
        </mat-form-field>
        <mat-label>{{provider.validatorName || ("validation_reference" | locale)}}</mat-label>
        <mat-form-field appearance="outline">
            <div class="validationInput">
                <input matInput
                formControlName="reference"
                autocomplete="off">
            </div>
        </mat-form-field>
    </form>
    <p class="referenceText" (click)="redirectToExplanation()">{{"reference_number_explanation" | locale}} {{(provider.validatorName || ("validation_reference" | locale) | lowercase)}}?</p>
    <p *ngIf="registrationErrorMessage" class="errorText"> {{registrationErrorMessage | locale}}</p>
    <button mat-flat-button color="accent" (click)="validateData()"
        [disabled]="!validationFormGroup.valid || continueDisabled">
        {{"continue" | locale}}
        <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>
</div>
