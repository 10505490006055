<div class="mat-dialog-header">
  <h1 mat-dialog-title>
    {{ 'dialog_title_warning' | locale }}
  </h1>
  <mat-icon>
    info
  </mat-icon>
</div>
<div mat-dialog-content>
  <p>
    {{ 'doctor_not_available_1' | locale }}
    <span>
      {{ 'doctor_not_available_2' | locale }}
    </span>
  </p>
  <p>
    {{ 'doctor_not_available_3' | locale }}
  </p>
</div>
<div mat-dialog-actions>
  <button
    color="primary"
    mat-dialog-close
    mat-flat-button>
    {{ 'permissions_accept' | locale }}
  </button>
</div>
