<ng-container *ngIf="loaded; else notLoaded">
    <div class="background">
        <div class="gradient"></div>
        <app-background-image></app-background-image>
        <div class="logo">
            <app-logo></app-logo>
        </div>
        <app-sticker [title]="'login_sticker_message' | locale" *ngIf="!isNativePlatform"></app-sticker>
    </div>
    <div class="account-options right-confetti">
        <div class="buttons-container">
            <button mat-flat-button color="accent" class="login-button" [routerLink]="['../', socialPath]">
                {{"login_social" | locale}}
                <mat-icon svgIcon="east" class="east"></mat-icon>
            </button>
            <button mat-flat-button color="dark" class="register-button" [routerLink]="['../', registrationPath]">
                {{"login_create_account" | locale}}
                <mat-icon svgIcon="east" class="east"></mat-icon>
            </button>
        </div>
    </div>
</ng-container>
<ng-template #notLoaded>
  <app-loading-spinner class="bg-white"></app-loading-spinner>
</ng-template>

