<button color="accent" mat-icon-button [disabled]="true">
  <mat-icon>upload_file</mat-icon>
</button>
<div class="file">
  <p class="file-name" [ngClass]="{loaded: progress >= 100 }">
    {{ fileUpload?.file?.name || 'File name here!' }}
  </p>
  <p class="file-size" *ngIf="!message; else errorMessage">
    {{ (fileUpload.loaded || 0) | fileSize }} / {{ (fileUpload?.file?.size) || 0 | fileSize }}
  </p>
  <mat-progress-bar
    color="accent"
    mode="determinate"
    [value]="progress"
    *ngIf="progress < 100">
  </mat-progress-bar>
</div>
<!-- <button
  mat-icon-button
  (click)="deleteFile.emit()"
  *ngIf="progress >= 100 && !message; else retryFileUpload">
  <mat-icon>delete_outline</mat-icon>
</button> -->

<button
  mat-icon-button
  (click)="cancelUpload.emit()"
  *ngIf="progress < 100">
  <mat-icon>clear</mat-icon>
</button>

<button
  *ngIf="message"
  mat-icon-button
  (click)="retryUpload.emit()">
  <mat-icon>refresh</mat-icon>
</button>
<ng-template #retryFileUpload>
</ng-template>

<ng-template #errorMessage>
  <p class="error-message">
    {{ message }}
  </p>
</ng-template>
