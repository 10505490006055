import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { MedicalCondition } from 'src/app/models/medical-condition.model';
import { MedicalHistoryCategory } from 'src/app/models/medical-history-category.model';
import { PatientMedicalRecord } from 'src/app/models/patient-medical-record.model';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CallService } from 'src/app/services/call.service';
import { MedicalHistoryCategoryService } from 'src/app/services/medical-history-category.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css'],
  providers: [MedicalHistoryCategoryService],
})
export class MedicalHistoryComponent implements OnInit, OnDestroy {
  medicalHistoryCategories: MedicalHistoryCategory[] = [];
  medicalConditionIds = new Set<string>();
  patientMedicalConditions!: Set<string>;
  noMedicalCondition!: MedicalCondition;
  steps!: number;
  currentStepIndex!: number;
  hasMedicalCondition = false;
  patient!: Patient;

  constructor(
    private router: Router,
    private callService: CallService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
    private navigationService: NavigationService,
    private medicalHistoryService: MedicalHistoryCategoryService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.navigationService.hideMenu();
    this.navigationService.hideOptions();

    const { specialty, caller, doctor } = this.callService.callData;
    const isShortFlow = this.patientService.hasShortFlow();

    if (!isShortFlow && ((!specialty && !doctor) || !caller)) {
      this.router.navigate([`/${AppPaths.NAVBAR}`]);
    } else {
      this.patient = caller;
      await this.getMedicalHistoryCategories();
      this.getMedicalRecordsForPatient();
      this.patientService
        .getFamilyGroup()
        .then(async ({ members }: FamilyGroup) => {
          const hasFamilyGroup = members.length > 1;
          
          this.steps = hasFamilyGroup ? 4 : 3;
          this.currentStepIndex= hasFamilyGroup ? 2 : 1;

          if(isShortFlow && this.callService.hasMotive()) {
            this.steps--;
            this.currentStepIndex--;
          }
          const provider = await this.patientService.getProvider();
          if (provider.requiresHealthInsuranceInformation) {
            this.steps++;
            this.currentStepIndex++;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.navigationService.showMenu();
    this.navigationService.showOptions();
  }

  async getMedicalHistoryCategories(): Promise<void> {
    await this.medicalHistoryService
      .getMedicalHistoryCategories()
      .then((data: MedicalHistoryCategory[]) => {
        this.medicalHistoryCategories = data;
        data.forEach((medicalHistoryCategory: MedicalHistoryCategory) => {
          medicalHistoryCategory.medicalConditions.forEach(
            (medicalCondition: MedicalCondition) => {
              if (medicalCondition.code === 0) {
                this.noMedicalCondition = medicalCondition;
              }
            }
          );
        });
      });
  }

  async getMedicalRecordsForPatient(): Promise<void> {
    await this.medicalHistoryService
      .getMedicalRecordsForPatient(this.patient.id)
      .then((medicalRecords: PatientMedicalRecord[]) => {
        this.patientMedicalConditions = new Set<string>();
        medicalRecords.forEach((medicalRecord) => {
          this.addMedicalConditionInSets(medicalRecord.id);
        });
        if (this.medicalConditionIds.has(this.noMedicalCondition.id)) {
          this.hasMedicalCondition = true;
        }
      });
  }

  addMedicalConditionInSets(medicalConditionId: string): void {
    this.medicalConditionIds.add(medicalConditionId);
    this.patientMedicalConditions.add(medicalConditionId);
  }

  onSelectCondition(medicalCondition: MedicalCondition): void {
    this.hasMedicalCondition = medicalCondition.code === 0;
    if (this.hasMedicalCondition) {
      this.medicalConditionIds.clear();
    } else if (this.medicalConditionIds.has(this.noMedicalCondition?.id)) {
      this.medicalConditionIds.delete(this.noMedicalCondition?.id);
    }

    this.addMedicalConditionInSets(medicalCondition.id);
  }

  unSelectCondition(medicalCondition: MedicalCondition): void {
    this.patientMedicalConditions.delete(medicalCondition.id);
    this.medicalConditionIds.delete(medicalCondition.id);
  }

  goToNextStep(): void {
    const currentRecords:string[] = []
    this.callService.records = this.medicalHistoryCategories.reduce((acc, medicalHistoryCategory) => {
      medicalHistoryCategory.medicalConditions.forEach((medicalCondition) => {
        if (this.medicalConditionIds.has(medicalCondition.id)) {
          acc.push(medicalCondition.code.toString());
          currentRecords.push(medicalCondition.name);
        }
      });
      return acc;
    }, [] as string[]);
    this.medicalHistoryService
      .updateMedicalRecordsForPatient(this.patient.id, [...this.medicalConditionIds])
      .then(() => {
        this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_SELECT_RECORDS, {records: currentRecords})
        this.router.navigate([`/${AppPaths.CALL}`, AppPaths.CALL_SUGGESTIONS]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
