<app-nav-content>
    <h1 title class="title">{{'download_files_title'|locale}}</h1>

    <div card class="card-content">
        <div class="prescriptions-container" *ngIf="prescriptions?.length">
            <h4>{{'download_files_prescriptions_title'|locale}}</h4>
            <mat-divider></mat-divider>
            <div class="prescriptions-container" *ngFor="let prescription of prescriptions">
                <app-select-item
                                [itemText]="prescription.medicines[0].active"
                                [itemSubtext]="''"
                                [color]="'#C5FF00'"
                                [iconName]="'pill'"
                                [south]="true"
                                (click)="getUrl(prescription.fileS3Key)"></app-select-item>
                <!-- duplicates -->
                <app-select-item *ngIf="prescription.sendDuplicate"
                                itemText="{{prescription.medicines[0].active + ' - '+ ('download_files_duplicate'|locale)}}"
                                [itemSubtext]="''"
                                [color]="'#C5FF00'"
                                [iconName]="'pill'"
                                [south]="true"
                                (click)="getUrl(prescription.fileDuplicateS3Key)"></app-select-item>
            </div>
            <app-select-item *ngFor="let instruction of instructions"
                                itemText="{{'download_files_instructions'|locale}}"
                                [itemSubtext]="''"
                                [color]="'#C5FF00'"
                                [iconName]="'pill'"
                                [south]="true"
                                (click)="getUrl(instruction)"></app-select-item>
        </div>
        <br>

        <div class="medical-orders-container" *ngIf="medicalOrders?.length">
            <h4>{{'download_files_medical_orders_title'|locale}}</h4>
            <mat-divider></mat-divider>
            <app-select-item *ngFor="let medicalOrder of medicalOrders"
                            [itemText]="medicalOrder.description"
                            [itemSubtext]="''"
                            [color]="'#29293B'"
                            [iconName]="'prescription-green'"
                            [south]="true"
                            (click)="getUrl(medicalOrder.fileS3Key)"></app-select-item>
        </div>
        <br>

        <div class="medical-care-record-container" *ngIf="medicalCareRecord">
            <h4>{{'download_files_medical_care_records_title'|locale}}</h4>
            <mat-divider></mat-divider>
            <app-select-item [itemText]="'download_files_medical_care_records'|locale"
                            [itemSubtext]="''"
                            [color]="'#FF4D7C'"
                            [iconName]="'medicine'"
                            [south]="true"
                            (click)="getUrl(medicalCareRecord.fileS3Key)"></app-select-item>
        </div>
    </div>
</app-nav-content>
