import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnChanges {
  @Input() numberOfSteps!: number;
  @Input() currentStepIndex = 0;
  steps: number[] = [];

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numberOfSteps) {
      this.steps = Array(this.numberOfSteps).fill(0).map((_, i) => i);
    }
  }

}
