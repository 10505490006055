import { Injectable } from '@angular/core';
import { SpecialtyConfig } from '../models/specialty-config.model';
import { LocaleService } from './locale.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private requestService: RequestService,
    private localeService: LocaleService) { }

  getBasicRegistrationInfo(){
    return this.requestService.get('/provider/basicRegistrationInfo')
  }
  getTermsAndConditions(providerId:string){
    var locale = this.localeService.getLocale().toLowerCase().split("-")[0] //Send only language code
    return this.requestService.get('/provider/' + providerId + '/tyc?locale=' + locale)
  }

  getSatisfactionSurveyQuestions(providerId: string): Promise<any> {
    return this.requestService.get(`/provider/${providerId}/satisfactorySurveyQuestions`)
  }

  getProviderSpecialtyConfig(providerId: string): Promise<SpecialtyConfig[]> {
    return this.requestService.get(`/specialtyProviderConfig/provider/${providerId}`)
  }
}
