import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private requestService: RequestService) { }

  getCountries(){
    return this.requestService.get('/country?sort=name ASC')
  }
}
