<app-nav-content>
  <h2 title>
    {{ 'settings_title' | locale }}
  </h2>
  <ng-container ngProjectAs="[card]">
    <div>
      <app-select-item
        itemSubtext=""
        [itemText]="option.name | locale"
        [iconName]="option.icon"
        [routerLink]="option.linkTo"
        [ngClass]="[option.background]"
        *ngFor="let option of settingOptions">
      </app-select-item>
    </div>

    <button mat-flat-button (click)="logout()">
      {{ 'settings_logout' | locale }}
    </button>
  </ng-container>
</app-nav-content>
