<app-nav-content [ngClass]="{'no-calls': resultCalls?.calls?.length == 0 && !resultFilter }">
  <h2 title>{{'calls_history_title'|locale}}</h2>

  <div card>
    <ng-container *ngIf="(resultCalls && resultCalls.calls.length > 0) || resultFilter">
      <button mat-button color="accent" (click)="openCallFilters()">
        <span class="icon-button">
          <mat-icon>
            filter_alt
          </mat-icon>
        </span>
        <span class="text-button">
          {{ 'filter_button' | locale }}
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="resultCalls && resultCalls.calls.length > 0; else noCalls">
      <div class="card-content">
        <app-call-history-item
            [call]="call"
            *ngFor="let call of resultCalls.calls"
            [routerLink]="[detailsPath, call.id]">
        </app-call-history-item>
      </div>
      <mat-paginator
        style-paginator
        [length]="resultCalls?.total"
        [pageSize]="limit"
        [pageIndex]="currentPage"
        [showTotalPages]="4"
        [lengthSubject]="lengthSubject"
        (page)="pageChangeEvent($event)"
        hidePageSize>
      </mat-paginator>
    </ng-container>
  </div>
</app-nav-content>

<ng-template #noCalls>
  <p class="no-calls" *ngIf="!resultFilter; else noCallsFilter">
    {{'calls_history_no_calls'|locale}}
  </p>
  <ng-template #noCallsFilter>
    <div class="no-calls-filter">
      <p class="no-calls">
        {{'filter_no_calls_history'|locale}}
      </p>
    </div>
  </ng-template>
</ng-template>
