import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AuthService } from 'src/app/services/auth.service';
import { CapacitorService } from 'src/app/services/capacitor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.css']
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  hasConfetti = 'auth-confetti';
  auth0: any;
  showSticker = true;
  isNativePlatform: boolean;
  private subscription!: Subscription;
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private capacitorService: CapacitorService,
  ) {
    this.isNativePlatform = this.capacitorService.isNativePlatform();
   }

  ngOnInit(): void {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showSticker = !event.url.includes(AppPaths.REQUEST_PASSWORD_SENT)
      }
    })
  }

  onOutletLoaded(component: { auth0: any; }) {
    component.auth0 = this.authService.getAuth0()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
