import { Doctor } from './doctor.model';
import { Patient } from './patient.model';
import { Specialty } from './specialty.model';

export class CallData {
  reason!: string;
  caller!: Patient;
  doctor: Doctor | null;
  records: string[];
  specialty!: Specialty

  constructor(doctor: Doctor | null = null, specialty?: Specialty, caller?: Patient, reason?: string, records?: string[]) {
    this.doctor = doctor;
    if (specialty) {
      this.specialty = specialty;
    }
    if (reason) {
      this.reason = reason;
    }
    if (caller) {
      this.caller = caller;
    }
    this.records = records || [];
  }
}
