import { Directive, Host, Optional, Renderer2, Self, ViewContainerRef, Input, ElementRef } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface PageObject {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

@Directive({
  selector: "[style-paginator]"
})
export class StylePaginatorDirective {
  pageGapTxt = ". . .";
  rangeStart!: number;
  rangeEnd!: number;
  totalPages!: number;
  lastPageIndex!: number;
  buttons: any[] = [];
  currentPageObj: PageObject = {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
    previousPageIndex: 0
  };

  // quantity of button numbers to display in pagination
  @Input()
  showTotalPages: number = 4;

  // optional variable, only to be used together with the filter
  @Input()
  lengthSubject?: Subject<number>;

  get length$(): Observable<number> | undefined {
    return this.lengthSubject?.asObservable();
  }

  actionContainer!: ElementRef<HTMLDivElement>;
  nextPageNode!: ElementRef<HTMLDivElement>;

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private viewContainerRef: ViewContainerRef,
    private render: Renderer2
  ) {
    //  it only runs on page change
    this.matPag.page.subscribe((eventPage: PageObject) => {
      const { pageSize, pageIndex } = this.currentPageObj;
      if (pageSize != eventPage.pageSize && pageIndex != 0) {
        eventPage.pageIndex = 0;
      }
      this.currentPageObj = eventPage;
      this.initPageRange();
    });
  }

  public ngAfterViewInit(): void {
    this.actionContainer = this.viewContainerRef.element.nativeElement.querySelector(
      "div.mat-paginator-range-actions"
    );
    this.nextPageNode = this.viewContainerRef.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-next"
    );

    // it only runs when filtering the fields
    this.length$?.pipe(debounceTime(100)).subscribe(() => {
      this.setTotalPages();
      this.initPageRange();
     })

    this.setTotalPages();
    this.initPageRange();
  }

  private setTotalPages(): void {
    this.totalPages = this.matPag.getNumberOfPages();
    this.lastPageIndex = this.totalPages - 1;
  }

  private initPageRange(): void {
    this.calcRanges();
    this.buildPageNumbers();
  }

  private calcRanges(): void {
    const { pageIndex } = this.currentPageObj;
    const rangeEnd = pageIndex === 0 ? (this.showTotalPages - 1) : (pageIndex + this.showTotalPages - 2);
    this.rangeEnd = rangeEnd <= this.lastPageIndex ? rangeEnd : this.lastPageIndex;

    const rangeStart = this.rangeEnd - this.showTotalPages + 1
    this.rangeStart = rangeStart > 0 ? rangeStart : 0;
  }

  private buildPageNumbers() {
    // remove buttons before creating new ones
    if (this.buttons.length > 0) {
      this.buttons.forEach(button => {
        this.render.removeChild(this.actionContainer, button);
      });
      this.buttons.length = 0;
    }
    for (let i = 0; i < this.totalPages; i++) {
      if (i === 0 && i+1 < this.matPag.pageIndex) {
        this.insertButton(this.createButton(0, this.matPag.pageIndex));
        this.insertButton(this.createButton('startGap'));
      }
      if (i >= this.rangeStart && i <= this.rangeEnd) {
        this.insertButton(this.createButton(i, this.matPag.pageIndex));
      }
      if (i === this.rangeEnd && i !== 0 && i+1 <= this.lastPageIndex) {
        this.insertButton(this.createButton('endGap'));
        this.insertButton(this.createButton(this.lastPageIndex, this.matPag.pageIndex));
      }
    }
  }

  private insertButton(createdButton: ElementRef<HTMLElement>): void {
    this.render.insertBefore(
      this.actionContainer,
      createdButton,
      this.nextPageNode
    );
  }

  private createButton(buttonPageIndex: any, pageIndex?: number): ElementRef<HTMLElement> {
    const button = this.render.createElement("span");
    const pagingTxt = isNaN(buttonPageIndex) ? this.pageGapTxt : buttonPageIndex + 1;
    this.render.setStyle(button, "margin", ".5rem");
    this.render.setStyle(button, "cursor", "pointer");
    this.render.setStyle(button, "user-select", "none");

    switch (buttonPageIndex) {
      case pageIndex:
        this.render.setAttribute(button, "disabled", "disabled");
        this.render.setStyle(button, "color", "black");
        break;
      case 'startGap':
        let index = this.currentPageObj.pageIndex - this.showTotalPages;
        if (index < 0) index = 0;
        this.addClickEventListener(button, index);
        break;
      case 'endGap':
        let newIndex = this.currentPageObj.pageIndex + this.showTotalPages;
        if (newIndex >= this.totalPages) newIndex = this.lastPageIndex;
        this.addClickEventListener(button, newIndex);
        break;
      default:
        this.addClickEventListener(button, buttonPageIndex);
        break;
    }
    this.render.appendChild(button, this.render.createText(pagingTxt.toString()));
    this.buttons.push(button);
    return button;
  }

  private addClickEventListener(button: ElementRef<HTMLElement>, buttonPageIndex: number):void {
    this.render.listen(button, "click", () => {
      this.switchPage(buttonPageIndex);
    });
  }

  private switchPage(buttonPageIndex: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = buttonPageIndex;
    this.matPag["_emitPageEvent"](previousPageIndex);
    this.initPageRange();
  }

}
