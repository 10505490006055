import { Component, OnInit } from '@angular/core';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { Patient } from 'src/app/models/patient.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-my-family-group',
  templateUrl: './my-family-group.component.html',
  styleUrls: ['./my-family-group.component.css']
})
export class MyFamilyGroupComponent implements OnInit {
  familyGroupMembers: Patient[] = [];
  owner!: Patient;

  constructor(
    private patientService: PatientService
  ) { }

  async ngOnInit(): Promise<void> {
    this.owner = await this.patientService.getMe();
    this.patientService.getFamilyGroup().then((familyGroup: FamilyGroup) => {
      this.familyGroupMembers = familyGroup.members;
    });
  }

}
