import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalCareRecord } from 'src/app/models/medical-care-record';
import { MedicalOrder } from 'src/app/models/medical-order';
import { Prescription } from 'src/app/models/prescription';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.css']
})
export class DownloadFilesComponent implements OnInit {

  urls: string[] | undefined;
  prescriptions: Prescription[] | undefined
  medicalOrders: MedicalOrder[] | undefined
  medicalCareRecord: MedicalCareRecord | undefined
  instructions: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private callService:CallService,
    private navigationService: NavigationService
  ) {
    this.navigationService.hideMenu();
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      if(params.id){
        let call = await this.callService.getCall(params.id);
        this.urls = await this.callService.getCallUrlPdfs(params.id)

        this.prescriptions = call.prescriptions;
        this.medicalOrders = call.medicalOrders;
        this.medicalCareRecord = call.medicalCareRecord;

        this.prescriptions.forEach(prescription => {
          if(!this.instructions.includes(prescription.instructionsS3Key)) this.instructions.push(prescription.instructionsS3Key)
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.navigationService.showMenu();
  }

  getUrl(criteria:any) {
    let url = this.urls?.find( url => url.includes(criteria));
    if(url){
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.href = url;
      a.target = "_blank";
      a.download = "File";
      a.click();
    }
  }

}
