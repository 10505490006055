import { Directive, HostBinding } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[faqButton]'
})
export class FaqButtonDirective {
  @HostBinding('class.material-icons-rounded') backButtonClass = true;
  @HostBinding('attr.href')
  faqURL = environment.FAQ_URL;
  @HostBinding('attr.target')
  target = '_blank';

  constructor() { }

}
