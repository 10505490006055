<app-nav-content [showConfettiInSmallScreen]="false">
  <ng-container ngProjectAs="[title]">
    <app-stepper
      [numberOfSteps]="steps"
      [currentStepIndex]="currentStepIndex">
    </app-stepper>
    <h2 class="patient">
      {{ 'reason_patient' | locale }}
      <span class="patient-name">
        {{ patient?.name }}
      </span>
    </h2>
    <h1>
      <ng-container *ngIf="patientMedicalConditions?.size || 0 > 0; else first">
        {{'medical_history_title' | locale}}
        <span class="emphasis">{{'medical_history_emphasis' | locale}}</span>
      </ng-container>
      <ng-template #first>{{'medical_history_title_first' | locale}}</ng-template>
    </h1>
  </ng-container>
  <ng-container ngProjectAs="[card]">
    <app-stepper
      [numberOfSteps]="steps"
      [currentStepIndex]="currentStepIndex">
    </app-stepper>
    <mat-accordion displayMode="flat">
      <app-medical-history-category
        [ngClass]="{ last: last }"
        [medicalHistoryCategory]="category"
        [hasMedicalCondition]="hasMedicalCondition"
        [patientMedicalConditions]="patientMedicalConditions"
        (onSelectCondition)="onSelectCondition($event)"
        (unSelectCondition)="unSelectCondition($event)"
        *ngFor="let category of medicalHistoryCategories; last as last">
      </app-medical-history-category>
    </mat-accordion>
    <button
      mat-flat-button
      color="primary"
      (click)="goToNextStep()"
      [disabled]="medicalConditionIds.size === 0">
      {{ 'continue' | locale}}
      <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>
  </ng-container>
</app-nav-content>
