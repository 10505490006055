<app-header></app-header>
<app-nav-content [ngClass]="{'hide-footer': whiteLabel }">
  <div title class="call-container br-30">
    <div class="top">
      <div class="mat-icon-container pulse">
        <mat-icon svgIcon="phone_calling"></mat-icon>
      </div>
      <div class="text">
        <p class="calling br-30">
          {{ 'call_waiting_calling' | locale }}
        </p>
        <h1 class="specialty" *ngIf="specialty && !doctor">
          {{ specialty | localizedName: locale }}
        </h1>
        <h1 class="doctor" *ngIf="doctor">
          {{ doctor.firstName }} {{ doctor.lastName }}
        </h1>
        <p class="suggestion">
          {{ 'call_waiting_suggestion' | locale }}
        </p>
      </div>
    </div>
    <div *ngIf="false" class="demand">
      <div class="demand-status">
        <p>
          {{ "call_waiting_demand" | locale }}:
          <span class="high">
            {{ "call_waiting_demand_high" | locale }}
          </span>
        </p>
        <mat-progress-bar class="high" value="25"></mat-progress-bar>
      </div>
      <div class="demand-description">
        <p>
          {{ "call_waiting_demand_low_time" | locale}}
        </p>
      </div>
    </div>
  </div>
  <div card>
    <div class="warning br-30">
      <mat-icon svgIcon="exclamation"></mat-icon>
      <p> {{ "call_waiting_warning" | locale }}</p>
    </div>
    <p>
      {{ "call_waiting_message" | locale }}
    </p>
  </div>
</app-nav-content>

<app-call-options
  [ngClass]="{'hide-footer': whiteLabel }"
  [floatingButton]="false"
  [videocamera]="(camera$ | async)!"
  [microphone]="(microphone$ | async)!"
  (hangout)="cancelCall()"
  (microphoneChange)="toggleMicrophone()"
  (videocameraChange)="toggleCamera()">
</app-call-options>
