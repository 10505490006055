import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  constructor() { }

  getImage(){
    return 'background-images/'+Math.floor(Math.random() * (5 - 1) + 1)+'.png'
  }
}
