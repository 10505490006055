<div class="right-content">
  <mat-icon svgIcon="lad"></mat-icon>
  <app-nav-content>
    <ng-container ngProjectAs="[title]">
      <h1>
        {{ provider ? provider!.loginValidatorTextHeader : ("validation_explanation_default_header" | locale)}}
      </h1>
    </ng-container>
    <div card class="navigation-sheet-content">
      <img [src]="provider ? provider!.loginValidatorImage : customImage">
      <p>
        {{ provider ? provider!.loginValidatorTextFooter : ("validation_explanation_default_footer" | locale)}}
      </p>
    </div>
  </app-nav-content>
</div>
