<app-nav-content>
  <h1 title>
    {{ 'my_family_group' | locale }}
  </h1>
  <ng-container ngProjectAs="[card]">
    <app-family-group-list
      [selectedMember]="owner"
      [members]="familyGroupMembers">
    </app-family-group-list>
  </ng-container>
</app-nav-content>
