<h1 class="title">{{'login_social_title_email'|locale}}</h1>
<div class="login-options">
    <form [formGroup]="loginFormGroup" class="form-group">
        <mat-label class="input-label">{{"Email"}}</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="email" formControlName="email" [errorStateMatcher]="matcher"
                placeholder="{{'login_email_placeholder' |locale }}">
        </mat-form-field>
        <mat-label class="input-label">{{"password" | locale}}</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <a
          mat-button
          [disableRipple]="true"
          class="no-focus-overlay"
          routerLink="../password-reset">
          {{"login_reset_password" | locale}}
        </a>
        <br>
        <div class="error-message-container" *ngIf="failedLoginErrorMessage">
            <mat-error class="error-message error-login">
                {{'login_error' | locale}}
            </mat-error>
        </div>
        <br>
        <button mat-flat-button color="primary" (click)="login()">
            {{"login_button" | locale}}
            <mat-icon svgIcon="east" class="east"></mat-icon>
        </button>
    </form>
</div>
