import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StickerService {
  private titleBehaviorSubject = new BehaviorSubject<string | null>(null);

  get title$(): Observable<string | null> {
    return this.titleBehaviorSubject.asObservable();
  }

  get title(): string | null {
    return this.titleBehaviorSubject.value;
  }

  set title(value: string | null) {
    this.titleBehaviorSubject.next(value);
  }

  private descriptionBehaviorSubject = new BehaviorSubject<string | null>(null);

  get description$(): Observable<string | null> {
    return this.descriptionBehaviorSubject.asObservable();
  }

  get description(): string | null {
    return this.descriptionBehaviorSubject.value;
  }

  set description(value: string | null) {
    this.descriptionBehaviorSubject.next(value);
  }

  constructor() { }
}
