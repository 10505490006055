import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import * as _ from 'lodash';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// MATERIAL
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS_FACTORY} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';

//PIPES
import {LocalePipe} from './locale.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

//SERVICES
import { LocaleService } from './services/locale.service';

//DIRECTIVES
import { StylePaginatorDirective } from './directives/paginator/style-paginator.directive';

//COMPONENTS
import { LoginComponent } from './components/login/login/login.component';
import { SocialLoginComponent } from './components/login/social-login/social-login.component';
import { Auth0SocialLoginComponent } from './components/login/auth0-social-login/auth0-social-login.component';
import { Auth0EmailLoginComponent } from './components/login/auth0-email-login/auth0-email-login.component';
import { LogoComponent } from './components/logo/logo.component';
import { FooterComponent } from './components/footer/footer.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { StickerComponent } from './components/sticker/sticker.component';
import { BackgroundImageComponent } from './components/background-image/background-image.component';
import { RegistrationComponent } from './components/registration/registration/registration.component';
import { ProviderSelectComponent } from './components/registration/provider-select/provider-select.component';
import { UserValidationComponent } from './components/registration/user-validation/user-validation.component';
import { Auth0RegistrationComponent } from './components/registration/auth0-registration/auth0-registration.component';
import { Auth0EmailRegistrationComponent } from './components/registration/auth0-email-registration/auth0-email-registration.component';
import { DataValidationComponent } from './components/data-validation/data-validation.component';
import { DataValidationRegistrationComponent } from './components/registration/data-validation-registration/data-validation-registration.component';
import { DataValidationTokenComponent } from './components/registration/data-validation-token/data-validation-token.component';
import { CredentialValidationComponent } from './components/login/credential-validation/credential-validation.component';
import { ValidatorExplanationComponent } from './components/registration/validator-explanation/validator-explanation.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { WarningComponent } from './components/warning/warning.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FamilyGroupComponent } from './components/family-group/family-group.component';
import { SelectReasonComponent } from './components/select-reason/select-reason.component';
import { FamilyGroupListComponent } from './components/family-group-list/family-group-list.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import { MedicalHistoryCategoryComponent } from './components/medical-history-category/medical-history-category.component';
import { HomeComponent } from './components/home/home.component';
import { SelectItemComponent } from './components/select-item/select-item.component';
import { NavContentComponent } from './components/nav-content/nav-content.component';
import { CallWaitingComponent } from './components/call-waiting/call-waiting.component';
import { CallSuggestionsComponent } from './components/call-suggestions/call-suggestions.component';
import { CallComponent } from './components/call/call.component';
import { HeaderComponent } from './components/header/header.component';
import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { FaqButtonDirective } from './directives/faq-button/faq-button.directive';
import { CallOptionsComponent } from './components/call-options/call-options.component';
import { LocalizedNamePipe } from './pipes/localized-name.pipe';
import { RequiredPermissionsComponent } from './components/required-permissions/required-permissions.component';
import { TokenIncomeComponent } from './components/registration/token-income/token-income.component';
import { CallCancelationWarningComponent } from './components/call-cancelation-warning/call-cancelation-warning.component';
import { OnCallComponent } from './components/on-call/on-call.component';
import { RateCallComponent } from './components/rate-call/rate-call.component';
import { DoctorInfoComponent } from './components/doctor-info/doctor-info.component';
import { FileProgressBarComponent } from './components/file-progress-bar/file-progress-bar.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FileUploadDirective } from './directives/file-upload/file-upload.directive';
import { SettingsComponent } from './components/settings/settings.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { MyFamilyGroupComponent } from './components/my-family-group/my-family-group.component';
import { SettingOptionsComponent } from './components/setting-options/setting-options.component';
import { AccountComponent } from './components/account/account.component';
import { CallEndedComponent } from './components/call-ended/call-ended.component';
import { CallsHistoryComponent } from './components/calls-history/calls-history.component';
import { CallHistoryItemComponent } from './components/call-history-item/call-history-item.component';
import { SNACK_BAR_DEFAULT_OPTIONS } from './consts/snack-bar-default-options.const';
import { SpecialtyAvailableHoursPipe } from './pipes/specialty-available-hours.pipe';
import { SpecialtySchedulesComponent } from './components/specialty-schedules/specialty-schedules.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DISABLED_SPINNER_PATHS } from './tokens/disabled-spinner-paths.token';
import { DISABLED_SPINNER_ENDPOINTS } from './consts/disabled-spinner-endpoints.const';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { CallDetailComponent } from './components/call-detail/call-detail.component';
import { DoctorSearchComponent } from './components/doctor-search/doctor-search.component';
import { DoctorDetailComponent } from './components/doctor-detail/doctor-detail.component';
import { DownloadFilesComponent } from './components/download-files/download-files.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AppointmentCountdownComponent } from './components/appointment-countdown/appointment-countdown.component';
import { DoctorNotAvailableComponent } from './components/doctor-not-available/doctor-not-available.component';
import { CallFiltersComponent } from './components/call-filters/call-filters.component';
import { DoctorWorkingHoursPipe } from './pipes/doctor-working-hours.pipe';
import { DoctorSchedulesComponent } from './components/doctor-schedules/doctor-schedules.component';
import { SchedulesComponent } from './components/schedules/schedules.component';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { PasswordResetComponent } from './components/login/password-reset/password-reset.component';
import { SubscribeDialogComponent } from './components/registration/subscribe-dialog/subscribe-dialog.component';
import { RequestSentComponent } from './components/login/request-sent/request-sent.component';
import { RequestAffiliateInfoComponent } from './components/request-affiliate-info/request-affiliate-info.component';
import { RoundPipe } from './pipes/round.pipe';
import { RateStoreComponent } from './components/rate-store/rate-store.component';
import { BukealaAppointmentComponent } from './components/bukeala-appointment/bukeala-appointment.component';



export function setupTranslateFactory(service: LocaleService): Function {
  var locale: any;
  if(_.isEmpty(service.getLocaleFromLocalStorage())) {
    const provider = localStorage.getItem('provider');
    const languageProvider = provider ? JSON.parse(provider).language : undefined;
    locale = languageProvider ?? window.navigator.languages;
    return  () => service.setLocaleInStorage(locale, "session");
  } else {
    locale = service.getLocaleFromLocalStorage();
    service.setLocale(locale);
    return  () => service.use(locale);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SocialLoginComponent,
    Auth0SocialLoginComponent,
    Auth0EmailLoginComponent,
    LocalePipe,
    LogoComponent,
    FooterComponent,
    DialogComponent,
    StickerComponent,
    BackgroundImageComponent,
    RegistrationComponent,
    ProviderSelectComponent,
    UserValidationComponent,
    Auth0RegistrationComponent,
    Auth0EmailRegistrationComponent,
    DataValidationComponent,
    DataValidationRegistrationComponent,
    DataValidationTokenComponent,
    CredentialValidationComponent,
    ValidatorExplanationComponent,
    TermsAndConditionsComponent,
    WarningComponent,
    NavbarComponent,
    FamilyGroupComponent,
    SelectReasonComponent,
    FamilyGroupListComponent,
    StepperComponent,
    MedicalHistoryComponent,
    MedicalHistoryCategoryComponent,
    HomeComponent,
    SelectItemComponent,
    NavContentComponent,
    CallWaitingComponent,
    CallSuggestionsComponent,
    CallComponent,
    HeaderComponent,
    BackButtonDirective,
    FaqButtonDirective,
    CallOptionsComponent,
    LocalizedNamePipe,
    RequiredPermissionsComponent,
    TokenIncomeComponent,
    CallCancelationWarningComponent,
    OnCallComponent,
    RateCallComponent,
    DoctorInfoComponent,
    FileProgressBarComponent,
    FileSizePipe,
    FileUploadDirective,
    SettingsComponent,
    FileListComponent,
    MyFamilyGroupComponent,
    SettingOptionsComponent,
    AccountComponent,
    CallEndedComponent,
    CallsHistoryComponent,
    CallHistoryItemComponent,
    SpecialtyAvailableHoursPipe,
    SpecialtySchedulesComponent,
    LoadingSpinnerComponent,
    CallDetailComponent,
    RequiredPermissionsComponent,
    DoctorSearchComponent,
    DoctorDetailComponent,
    DownloadFilesComponent,
    AppointmentCountdownComponent,
    DoctorNotAvailableComponent,
    CallFiltersComponent,
    DoctorWorkingHoursPipe,
    DoctorSchedulesComponent,
    SchedulesComponent,
    NumbersOnlyDirective,
    PasswordResetComponent,
    SubscribeDialogComponent,
    RequestSentComponent,
    RequestAffiliateInfoComponent,
    RoundPipe,
    StylePaginatorDirective,
    SafeUrlPipe,
    RateStoreComponent,
    BukealaAppointmentComponent,
  ],
  imports: [
    MatListModule,
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatDividerModule,
    MatStepperModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSnackBarModule,
    MatPaginatorModule,
    CommonModule,
    NgxIntlTelInputModule
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ LocaleService ],
      multi: true
    },
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: LOCALE_ID, useValue: "en-GB" },
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' }},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS_FACTORY, useValue: SNACK_BAR_DEFAULT_OPTIONS},
    {provide: DISABLED_SPINNER_PATHS, useValue: DISABLED_SPINNER_ENDPOINTS},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
