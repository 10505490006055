import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-cancelation-warning',
  templateUrl: './call-cancelation-warning.component.html',
  styleUrls: ['./call-cancelation-warning.component.css']
})
export class CallCancelationWarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
