<div class="content">
    <div class="background">
        <div class="gradient"></div>
        <app-background-image></app-background-image>
        <div class="logo">
            <app-logo></app-logo>
        </div>
    </div>
    <div class="credential-content">
        <p class="title">{{'login_update_credential'|locale}}</p>
        <div class="options">
            <form [formGroup]="form" class="form-group">
                <mat-label>{{"validation_credential" | locale}}</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="validator">
                </mat-form-field>
                <p class="referenceText" (click)="redirectToExplanation()">{{"credential_explanation" | locale}}</p>
                <button mat-flat-button
                class="submit-btn submit continue-button"
                (click)="validate()"
                color="primary"
                [disabled]="!form.valid || continueDisabled">
                    {{"update" | locale}}
                </button>
            </form>
        </div>
    </div>
</div>
