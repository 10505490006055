import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Patient } from 'src/app/models/patient.model';
import { BukealaService } from 'src/app/services/bukeala.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { PatientService } from 'src/app/services/patient.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bukeala-appointment',
  templateUrl: './bukeala-appointment.component.html',
  styleUrls: ['./bukeala-appointment.component.css']
})
export class BukealaAppointmentComponent implements OnInit {

  me!: Patient;
  iframeURL!: SafeResourceUrl;

  whiteLabel: boolean;

  constructor(
    private registrationService: RegistrationService,
    private patientService: PatientService,
    private bukealaService: BukealaService,
    private sanitizer: DomSanitizer,
    private spinnerService: LoadingSpinnerService,
  ) { 
    this.whiteLabel = this.registrationService.whiteLabel;
  }

  async ngOnInit(): Promise<void> {
    this.me = await this.patientService.getMe();

    this.generatePatientHashKeyBukeala();
  }

  private async generatePatientHashKeyBukeala(): Promise<void> {
    const response = await this.bukealaService.generatePatientHashKey(this.me.id);

    if(response) {
      this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.appointmentURL);
      this.spinnerService.showSpinner();
    }
  }

  hideSpinner(): void {
    this.spinnerService.hideSpinner();
  }

}
