<div class="item-container" *ngIf="call && call.doctor">
    <div class="item-icon">
        <img src="{{call.doctor.image || 'assets/doctor_default.png'}}" alt="doctor-picture">
    </div>
    <div class="item-text-container">
        <h4 class="item-text">{{call.doctor.firstName}} {{call.doctor.lastName}}</h4>
        <h5 class="item-subtext">{{call.specialty.localizedName[locale]}}</h5>
        <h6 class="item-subtext item-subtext-prescription"
            *ngIf="call.prescriptions.length > 0 || call.medicalOrders.length > 0 || call.medicalCareRecord">
            {{'calls_history_item_subtext_prescription'|locale}}
        </h6>
    </div>
    <div class="datetime">
        <h5 class="date">{{call.createdAt|date: 'dd/MM/yy'}}</h5>
        <h5 class="hour">{{call.createdAt|date:'shortTime'}}</h5>
    </div>
    <div class="item-arrow">
        <mat-icon svgIcon="arrow" class="arrow"></mat-icon>
    </div>
</div>
