import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { LocalePipe } from 'src/app/locale.pipe';
import { Patient } from 'src/app/models/patient.model';
import { LocaleService } from 'src/app/services/locale.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  providers: [
    LocalePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'es-ar'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}
  ],
})
export class AccountComponent implements OnInit, AfterViewInit {
  patient!: Patient;
  formGroup!: FormGroup;
  minDate = moment().subtract(120, 'years').toDate();
  maxDate = new Date();
  readonly searchCountryFields = [SearchCountryField.Iso2, SearchCountryField.Name];
  readonly phoneNumberFormat = PhoneNumberFormat.International;
	readonly preferredCountries: CountryISO[] = [CountryISO.Argentina, CountryISO.Brazil, CountryISO.UnitedStates];
  selectedCountry!: CountryISO;

  constructor(
    private localePipe: LocalePipe,
    private formBuilder: FormBuilder,
    private toastService: MatSnackBar,
    private localeService: LocaleService,
    private dateAdapter: DateAdapter<any>,
    private patientService: PatientService
  ) {

  }

  ngOnInit(): void {
    this.dateAdapter.setLocale(this.localeService.getLocale());
    this.selectedCountry = this.localeService.getCountryISO();
  }

  ngAfterViewInit(): void {
    this.patientService.getMe().then((patient) => {
      this.patient = patient;
      this.buildForm();
    });
  }

  get phoneControl(): FormControl {
    return this.formGroup.get('phone') as FormControl;
  }

  get mobilePhoneControl(): FormControl {
    return this.formGroup.get('mobilePhone') as FormControl;
  }

  get birthDateControl(): FormControl {
    return this.formGroup.get('birthDate') as FormControl;
  }

  get ageControl(): FormControl {
    return this.formGroup.get('age') as FormControl;
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      email: [
        { value: this.patient.email, disabled: !!this.patient.email },
        this.patient.email ? [] : [Validators.required, Validators.email],
      ],
      secondaryEmail: [
        this.patient.secondaryEmail,
        this.patient.secondaryEmail ? [] : [Validators.email],
      ],
      phone: [
        this.patient.phone,
        [
          Validators.maxLength(15),
          Validators.minLength(10),
        ],
      ],
      mobilePhone: [
        { value: this.patient.mobilePhone, disabled: !!this.patient.mobilePhone },
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.minLength(10),
        ],
      ]
    });

    if (this.patient.birthDate) {
      this.addAgeControl();
    } else {
      this.addBirthDateControl();
    }
  }

  private addAgeControl(): void {
    this.formGroup.addControl('age', new FormControl({value: this.patient.age, disabled: true}));
  }

  private addBirthDateControl(): void {
    this.formGroup.addControl('birthDate', new FormControl(
      null,
      [Validators.required],
    ));
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    const patient: Partial<Patient> = {
      ...this.formGroup.value,
      mobilePhone: this.mobilePhoneControl?.value?.e164Number
    };
    if (this.phoneControl?.value?.e164Number) {
      patient.phone = this.phoneControl?.value?.e164Number;
    }

    if (this.birthDateControl && !this.birthDateControl?.disabled) {
      patient.birthDate = this.birthDateControl.value;
      patient.age = moment().diff(this.birthDateControl.value, 'years');
    }


    this.patientService
      .update(this.patient.id, patient)
      .then(() => {
        this.patient = { ...this.patient, ...patient };
        if (!this.birthDateControl?.disabled) {
          this.formGroup.removeControl('birthDate');
          this.addAgeControl();
        }
        this.patientService.patient = this.patient;
        this.toastService.open(
          this.localePipe.transform('account_updated'),
          undefined,
          {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: ['toast', 'success'],
          }
        );
      })
      .catch(({ error, message }: HttpErrorResponse) => {
        this.toastService.open(error?.message || message, undefined, {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['toast', 'error'],
        });
      });
  }
}
