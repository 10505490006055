import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MedicalHistoryCategory } from '../models/medical-history-category.model';
import { RequestService } from './request.service';
import { PatientMedicalRecord } from '../models/patient-medical-record.model';

@Injectable()
export class MedicalHistoryCategoryService {

  constructor(
    private requestService: RequestService
  ) {}

  getMedicalHistoryCategories(): Promise<MedicalHistoryCategory[]> {
    return this.requestService.get('/patientMedicalRecord');
  }

  getMedicalRecordsForPatient(patientId: string): Promise<PatientMedicalRecord[]> {
    return this.requestService.get(`/patient/${patientId}/pathologicalHistory`)
  }

  updateMedicalRecordsForPatient(patientId: string, records: string[]): Promise<void> {
    return this.requestService.put(`/patient/${patientId}/pathologicalHistory`, { records })
  }
}
