import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LocaleService } from './locale.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient) { }

  private getUrl(urlEndpoint: string) {
    return environment.API_URL + urlEndpoint
  }

  parseUrlQueryParams(url: string, options: {}) {

    let urlQueryParams: any = options || {};
    Object.entries(urlQueryParams).forEach(([key, value]) => {
      url += (_.indexOf(url, '?') > 0 ? '&' : '?') + key + '=' + value;
    });

    return url;
  }

  get(urlEndpoint:string, criteria? :any): any {
    let url = this.getUrl(urlEndpoint)

    if(!_.isEmpty(criteria)) {
      url = this.parseUrlQueryParams(url, criteria);
    }

    return this.http.get(url).toPromise();
  }

  put(urlEndpoint:string, body: {}): any {
    let url = this.getUrl(urlEndpoint)
    return this.http.put(url, body).toPromise();
  }

  post(urlEndpoint:string, body: {}): any {
    let url = this.getUrl(urlEndpoint)
    return this.http.post(url, body).toPromise();
  }

  delete(urlEndpoint:string): any {
    let url = this.getUrl(urlEndpoint);
    return this.http.delete(url).toPromise();
  }
}
