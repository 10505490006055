import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.css']
})
export class SubscribeDialogComponent {
  ladContactEmail = environment.LAD_CONTACT_EMAIL;

  constructor() { }

  get mailToLink(): string {
    return `mailto:${this.ladContactEmail}`;
  }

}
