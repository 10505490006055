<app-sticker *ngIf="!isNativePlatform"
    [title]="'registration_sticker_title' | locale"
    [description]="'registration_sticker_description' | locale">
</app-sticker>
<app-stepper [numberOfSteps]="4"></app-stepper>

<form class="providerForm" [formGroup]="selectProviderFormGroup">
    <h1 class="provider-select-title">{{'registration_select_provider_title' | locale }}</h1>
    <mat-form-field appearance="outline">
        <mat-select [placeholder]="'registration_select_country' | locale" formControlName="country" (selectionChange)="onCountryChange()">
            <mat-option *ngFor="let country of countries" [value]="country">
                <img class="card-img-top" [src]="'/assets/icons/flags/' + country.countryCode + '.svg'" [alt]="country.countryCode"> <span class="country-span-name">{{country.name}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <input matInput formControlName="provider" [matAutocomplete]="auto"
            [placeholder]="'registration_select_provider' | locale "
            [readonly]="!selectProviderFormGroup.value.country">
        <mat-autocomplete class="custom-panel provider-select" #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let provider of filteredOptions | async" [value]="provider">
                <div class="provider-option">
                    <div class="provider-option-select-left">
                        <img class="logoImage" [src]="provider.registrationSelectorImage || defaultImage">
                        <p class="provider-name">{{provider.name}}</p>
                    </div>
                    <mat-icon svgIcon="arrow" class="arrow"></mat-icon>
                </div>
            </mat-option>
        </mat-autocomplete>
        <mat-icon matPrefix svgIcon="search" class="provider-search-icon"></mat-icon>
    </mat-form-field>
</form>
<div class="background-image">
    <button mat-flat-button color="accent" (click)="nextStep()"
        [disabled]="!selectProviderFormGroup.valid">
        {{"continue" | locale}}
        <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>
</div>
