<app-nav-content>
  <h1 title>
    {{ 'settings_my_account' | locale }}
  </h1>
  <ng-container ngProjectAs="[card]">
    <form
      *ngIf="formGroup"
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()">
      <div class="account-content">
        <div class="account-info">
          <p class="account-owner">
            {{ patient?.name || patient?.cleanName }}
          </p>
          <p class="calls">
            <span class="available-calls">
              {{ patient?.callsLeft }}
            </span>
            {{ 'account_available_calls' | locale }}
          </p>
        </div>
        <div class="account-form">
          <label>
            {{ (patient.birthDate ? 'account_age' : 'account_birthdate') | locale }}
          </label>
          <ng-container *ngIf="patient.birthDate; else birthDate">
            <mat-form-field>
              <input
                matInput
                type="number"
                formControlName="age">
            </mat-form-field>
          </ng-container>
          <ng-template #birthDate>
            <mat-form-field>
              <input
                matInput
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="picker"
                formControlName="birthDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </ng-template>
          <label>
            {{ 'account_email' | locale }}
          </label>
          <mat-form-field>
            <input matInput formControlName="email" [placeholder]="'account_email' | locale" type="email">
          </mat-form-field>
          <label for="mobilePhone">
            {{ 'account_phone_number' | locale }}
          </label>
          <ngx-intl-tel-input
            [cssClass]="'tel-input'"
            [ngClass]="{'disabled-field': !!patient.mobilePhone}"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="searchCountryFields"
            [selectFirstCountry]="false"
            [selectedCountryISO]="selectedCountry"
            [phoneValidation]="true"
            [numberFormat]="phoneNumberFormat"
            name="mobilePhone"
            formControlName="mobilePhone">
          </ngx-intl-tel-input>
          <div>
            <p class="error" *ngIf="mobilePhoneControl?.hasError('validatePhoneNumber')">
              {{ 'account_phone_number_error' | locale }}
            </p>
            <p class="error" *ngIf="formGroup.dirty && formGroup.touched && mobilePhoneControl?.hasError('required')">
              {{ 'reason_input_required' | locale }}
            </p>
          </div>
          <label>
            {{ 'account_secondary_email' | locale }}
          </label>
          <mat-form-field>
            <input matInput formControlName="secondaryEmail" placeholder="ingrid@llamandoaldoctor.com" type="email">
          </mat-form-field>
          <label for="phone">
            {{ 'account_secondary_phone_number' | locale }}
          </label>
          <ngx-intl-tel-input
            [cssClass]="'tel-input'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="searchCountryFields"
            [selectFirstCountry]="false"
            [selectedCountryISO]="selectedCountry"
            [phoneValidation]="true"
            [numberFormat]="phoneNumberFormat"
            name="phone"
            formControlName="phone">
          </ngx-intl-tel-input>
          <p class="error" *ngIf="phoneControl?.hasError('validatePhoneNumber')">
            {{ 'account_phone_number_error' | locale }}
          </p>
        </div>
      </div>
      <button
        type="submit"
        color="primary"
        mat-flat-button
        [disabled]="!formGroup.valid || formGroup.pristine">
        {{ "account_save" | locale }}
      </button>
    </form>
  </ng-container>
</app-nav-content>
