import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private requestService: RequestService) { }

  setAppointment(appointmentId: string): void {
    localStorage.setItem("appointment", appointmentId);
  }

  getAppointmentId(): string | null {
    return localStorage.getItem("appointment");
  }

  inAppointment(): boolean {
    return this.getAppointmentId() != null;
  }

  setAppointmentStatus(appointmentStatus: string): void {
    localStorage.setItem("appointmentStatus", appointmentStatus);
  }

  getAppointmentStatus (): string | null {
    return localStorage.getItem("appointmentStatus");
  }

  available(appointmentId : string): any {
    try {
      return this.requestService.get("/appointment/" + appointmentId + "/available");
    } catch (err : any) {
      return err._body;
    }
  }

  async validateAppointment(appointmentId: string){
    try {
      await this.requestService.get("/appointment/"+appointmentId+"/available")
      return {valid: true};
    } catch (err : any) {
      let message;
      var error = err.error || err;
      if(error.appointmentStatus == "ended") message = "dialog_appointment_error_ended";
      if(error.name == "notFound") message = "dialog_appointment_error_not_found";
      return {valid: false, message: message || error.message}
    }
  }

  getAppointment(appointmentId: string){
    return this.requestService.get('/appointment/' + appointmentId)
  }
}
