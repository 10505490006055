import { Schedule } from "../models/schedule.model";

export function objectToSchedules(
  object: { [key: string]: number[] }
): Schedule[] {
  const daysOfWeek =['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  return Object.entries<number[]>(object).reduce(
    (acc: Schedule[], [range, days]: [string, number[]]) => {
      let [start, end] = range.split("-");
      start = start.replace(".", ":");
      end = end.replace(".", ":");
      days.forEach((day) => {
        let schedule = acc.find((schedule) => schedule.day === day);
        if (schedule) {
          schedule.hours.push({
            start,
            end,
          });
        } else {
          acc.push({
            day,
            dayName: daysOfWeek[day],
            hours: [{ start, end }],
          });
        }
      });
      return acc;
    },
    [] as Schedule[]
  ).sort((a, b) => a.day - b.day);
}
