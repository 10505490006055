import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.llamandoaldoctor',
  appName: 'lad-pwa-patients',
  webDir: 'dist/lad-pwa-patients',
  bundledWebRuntime: false,
  ios: {
    "contentInset": "always",
    scheme: 'LAD',
  },
  plugins: {
    SplashScreen: {
      launchAutoHide: false,
      backgroundColor: "#29293B",
      splashImmersive: false,
      layoutName: "splash_activity",
    },
  },
};

export default config;
