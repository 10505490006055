import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { PatientService } from './patient.service';
import * as auth from 'auth0-js';
import { LocaleService } from './locale.service';
import { RegistrationService } from './registration.service';
import * as moment from 'moment';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { CapacitorService } from './capacitor.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth0: any;

  constructor(
    public jwtHelper: JwtHelperService,
    private patientService: PatientService,
    private localeService: LocaleService,
    private registrationService: RegistrationService,
    private capacitorService: CapacitorService,
  ) {
    this.auth0 = new auth.WebAuth({
      domain: environment.AUTH0_DOMAIN,
      clientID: environment.AUTH0_CLIENTID,
    });
  }

  public getAuth0(): auth.WebAuth {
    return this.auth0;
  }

  public getToken(): any {
    return localStorage.getItem('id_token');
  }

  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public isAuthenticated(): boolean {
    var token = this.getToken();
    if (!token) return false;
    return !this.jwtHelper.isTokenExpired(token);
  }

  public validateAuthentication(idToken: string): boolean {
    var decodedToken = this.jwtHelper.decodeToken(idToken);
    if (
      this.jwtHelper.isTokenExpired(idToken) ||
      decodedToken.nbf > moment().unix()
    ) {
      this.logout();
      return false;
    }
    this.setItem('id_token', idToken);
    return true;
  }

  public async logout() {
    const patient = await this.patientService.getMe();
    localStorage.clear();
    this.registrationService.whiteLabel = false;
    if(Capacitor.isNativePlatform()) {
      this.logoutBrowserCapacitor();
    }
  }

  public async logoutBrowserCapacitor(federated: boolean = false) {
    const redirectUriCapacitor = this.capacitorService.getRedirectUri();
    const url = this.auth0.client.buildLogoutUrl({ returnTo: redirectUriCapacitor, federated });
    await Browser.open({ url: url})
    if(Capacitor.getPlatform() === 'ios')
      Browser.close();
  }

  async handleAuth0Login(token: string) {
    localStorage.clear();
    localStorage.setItem('id_token', token);
    let patient = await this.patientService.auth();
    let provider = patient.provider
    this.saveData(patient, provider);
  }

  async handleTokenLogin(token: string, appointmentId?: string) {
    var decodedToken = this.jwtHelper.decodeToken(token);
    if (decodedToken.nbf)
      localStorage.setItem('not_before', JSON.stringify(decodedToken.nbf));
    if (appointmentId) localStorage.setItem('appointment', appointmentId);
    localStorage.setItem('id_token', token);

    let patient = await this.patientService.getMe();
    let provider = patient.provider
    this.saveData(patient, provider);
  }

  saveData(patient: any, provider: any) {
    this.registrationService.setPatient(patient);
    this.registrationService.setProvider(provider);
    this.localeService.setProviderLanguage(provider);
  }

  handleAuth0Registration(token: string) {
    localStorage.setItem('id_token', token);
  }

  requestPasswordReset(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.auth0.changePassword(
        {
          email,
          connection: environment.AUTH0_REALM,
        },
        (err: any) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  }

  getBodyFromAuth0(token:string){
    return this.jwtHelper.decodeToken(token);
  }
}
