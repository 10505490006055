import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BackgroundService } from 'src/app/services/background.service';

@Component({
  selector: 'app-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.css'],
})
export class BackgroundImageComponent implements OnInit {
  @HostBinding('style.background-image')
  get backgroundImage(): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(
      `url(${this.backgroundImageURL})`
    );
  }

  private backgroundImageURL!: string;


  constructor(
    private domSanitizer: DomSanitizer,
    private backgroundService: BackgroundService
  ) {}

  ngOnInit(): void {
    this.backgroundImageURL = `/assets/${this.backgroundService.getImage()}`;
  }
}
