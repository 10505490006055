import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalePipe } from 'src/app/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'app-request-sent',
  templateUrl: './request-sent.component.html',
  styleUrls: ['./request-sent.component.css'],
  providers: [LocalePipe],
})
export class RequestSentComponent implements OnInit {
  email!: string;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private localePipe: LocalePipe,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {
    const email = this.activatedRoute.snapshot.queryParamMap.get('email');
    if (email) {
      this.email = email;
    } else {
      this.router.navigate(['/']);
    }
  }

  resetPassword() {
    this.spinnerService.toggleSpinner();
    this.authService
      .requestPasswordReset(this.email)
      .then(() => {
        this.spinnerService.toggleSpinner();
        this.snackBar.open(
          this.localePipe.transform('request_reset_password_sent'),
          undefined,
          {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['toast', 'success'],
          }
        );
        console.log('Password reset email sent');
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
