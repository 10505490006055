<div class="heart-emoji">
  <mat-icon svgIcon="heart" alt="heart emoji"></mat-icon>
</div>
<div class="title">
    <h1>{{ title }}</h1>
</div>
<p
  class="description"
  *ngIf="description">
  {{ description }}
</p>
<div class="store">
  <a href="https://play.google.com/store/apps/details?id=com.llamandoaldoctor&hl=es_419&gl=US" target="_blank">
    <mat-icon svgIcon="play_store" alt="google store logo" class="store-logo"></mat-icon>
  </a>
  <a href="https://apps.apple.com/ar/app/llamando-al-doctor/id1196903602" target="_blank">
    <mat-icon svgIcon="app_store" alt="apple store logo" class="store-logo"></mat-icon>
  </a>
</div>
