import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AppointmentService } from 'src/app/services/appointment.service';
import { LocaleService } from 'src/app/services/locale.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css']
})
export class WarningComponent implements OnInit, OnDestroy {

  emergencyPhone: any;
  provider: any;

  providerTitle!: string;

  constructor(
    private router: Router,
    private localeService: LocaleService,
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
    private appointmentService: AppointmentService
  ) { 
    if(this.registrationService.whiteLabel) {
      this.providerTitle = this.registrationService.getProviderTitle();
    }
  }

  ngOnInit(): void {
    this.navigationService.hideBackButton();
    this.navigationService.hideOptions();
    this.provider = this.registrationService.getProvider();
    this.emergencyPhone = this.getEmergencyPhone();
  }

  ngOnDestroy(): void {
    this.navigationService.showBackButton();
    this.navigationService.showOptions();
  }

  async continue(){
    var appointmentId = this.appointmentService.getAppointmentId();
    if(appointmentId){
      var appointment = await this.appointmentService.getAppointment(appointmentId);
      this.router.navigate([AppPaths.DOCTOR_DETAIL + "/" + appointment.doctor.id]);
    } else {
      this.router.navigate([`${AppPaths.NAVBAR}`])
    }
  }

  getEmergencyPhone() {
    if (!_.isUndefined(this.provider) && !_.isEmpty(this.provider.emergencyCall))
      return this.provider.emergencyCall;
    switch (this.localeService.getLocale()) {
      case 'pt':
      case 'pt-br':
        return '192';
      case 'es-co':
        return '123';
      case 'en':
      case 'en-us':
        return '911';
      default:
        return '107 o al 148';
    }
  }
}
