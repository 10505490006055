import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import { Patient } from 'src/app/models/patient.model';

@Component({
  selector: 'app-family-group-list',
  templateUrl: './family-group-list.component.html',
  styleUrls: ['./family-group-list.component.css'],
})
export class FamilyGroupListComponent implements AfterViewInit {
  @ViewChild('membersList') membersList!: MatSelectionList;
  @Input() members!: Patient[];
  @Input() selectedMember!: Patient;
  @Input() isSelectable = false;
  @Output() memberSelected = new EventEmitter<Patient>();

  constructor() {}

  ngAfterViewInit(): void {
    const titularIndex = this.members.findIndex((member) => member.owner === true);
    if (titularIndex !== -1) {
      const temp = this.members[0];
      this.members[0] = this.members[titularIndex];
      this.members[titularIndex] = temp;
    }
  }

  selectionChange(event: MatSelectionListChange): void {
    this.membersList.deselectAll();
    const selectedOption = event.options[0];
    selectedOption.selected = true;
    this.memberSelected.emit(selectedOption.value);
  }

  trackById(_: number, item: Patient): string {
    return item.id;
  }
}
