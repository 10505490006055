<div class="account-options">
    <app-logo *ngIf="!whiteLabel" class="bg-dark"></app-logo>
    <div class="img-container">
        <img *ngIf="provider" class="providerImage" [src]="provider.header ? provider.header : provider.registrationSelectorImage">
    </div>
    <p class="validationText"> {{"registration_personal_data" | locale }}</p>
    <div class="inputs">
        <form [formGroup]="dataFormGroup" class="form-group">

            <div *ngIf="providerLanguages && providerLanguages.length>1" class="optional-input">
                <mat-label class="input-label">{{"language" | locale}}</mat-label>
                <mat-form-field appearance="outline">
                    <mat-select (selectionChange)="onLocaleChanged()" [(value)]="selectedLanguage">
                        <mat-option *ngFor="let language of providerLanguages" [value]="language">{{language.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="patient.provider.askAllFields" class="optional-input">
                <mat-label class="input-label">{{"name_and_surname" | locale}}</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>

            <div *ngIf="!patient.email" class="optional-input">
                <mat-label class="input-label">{{"email" | locale}}</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="email" type="email">
                </mat-form-field>
            </div>

            <mat-label class="input-label">{{"birthDate" | locale}}</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="birthDate" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-label class="input-label">{{"phone" | locale}}</mat-label>
            <div class="wrapper">
                <ngx-intl-tel-input
                    [cssClass]="'tel-input'"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="searchCountryFields"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="selectedCountry"
                    [phoneValidation]="true"
                    [numberFormat]="phoneNumberFormat"
                    name="phone"
                    formControlName="phone">
                </ngx-intl-tel-input>
            </div>

            <mat-label class="input-label">{{"gender" | locale}}</mat-label>
            <mat-form-field appearance="outline">
                <mat-select formControlName="gender">
                    <mat-option *ngFor="let gender of genders" [value]="gender.value">{{ gender.name | locale }}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="south">south</mat-icon>
            </mat-form-field>

            <button mat-flat-button color="primary" [disabled]="!dataFormGroup.valid"
                (click)=continue()>
                {{"continue" | locale}}
                <mat-icon svgIcon="east" class="east"></mat-icon>
            </button>

        </form>
    </div>
</div>
