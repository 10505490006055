import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { timer, Subscription } from 'rxjs';

@Component({
  selector: 'app-appointment-countdown',
  templateUrl: './appointment-countdown.component.html',
  styleUrls: ['./appointment-countdown.component.css']
})
export class AppointmentCountdownComponent implements OnInit {

  timer:Subscription | undefined;
  timeLapse = 1000; //miliseconds
  countdownText = "";

  constructor(public dialogRef: MatDialogRef<AppointmentCountdownComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message1: string, message2: string, message3: string, time: number, title: string, canCancel: boolean}) { }

  ngOnInit(): void {
    let appointmentStart = moment().add(this.data.time ? this.data.time : 600,'seconds') // "600" -> 10 minutes in seconds
    this.timer = timer(0, this.timeLapse).subscribe(() => {
      let diff = appointmentStart.diff(moment(),'seconds')
      const minutes:number = Math.floor(diff / 60);
      this.countdownText = ('00' + minutes).slice(-2) + ':' + ('00' + diff % 60).slice(-2);
      if(diff <= 0) this.close();
    })
  }

  close(){
    this.timer!.unsubscribe();
    this.dialogRef.close();
  }

}
