import {
  Component,
  ComponentRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileUpload } from 'src/app/models/file-upload.model';

@Component({
  selector: 'app-file-progress-bar',
  templateUrl: './file-progress-bar.component.html',
  styleUrls: ['./file-progress-bar.component.css'],
})
export class FileProgressBarComponent {
  @Input() fileUpload!: FileUpload;
  @Input() message!: undefined | string;
  @Input() selfComponentRef!: ComponentRef<FileProgressBarComponent>;
  @Output() cancelUpload = new EventEmitter<void>();
  @Output() retryUpload = new EventEmitter<void>();
  @Output() deleteFile = new EventEmitter<void>();
  @HostBinding('class.animated')
  animated = true;
  @HostBinding('class.fade-out')
  fadeOut = false;
  @HostBinding('class.hidden')
  hidden = false;
  private readonly secondsToDestory = 2 * 1000;

  get progress(): number {
    if (!this.fileUpload?.file) {
      return 0;
    }
    return Math.round(
      (this.fileUpload.loaded * 100) / this.fileUpload.file.size!
    );
  }

  constructor() {}

  destroy(callback: () => void) {
    interval(this.secondsToDestory / 2)
      .pipe(take(2))
      .subscribe({
        next: (count: number) => {
          switch (count) {
            case 1:
              callback();
              this.selfComponentRef.destroy();
              break;
            case 0:
              this.fadeOut = true;
              setTimeout(() => (this.hidden = true), 1000);
              break;
          }
        },
      });
  }
}
