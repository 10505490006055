import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HideSpinnerGuard implements CanActivate {
  constructor(@Inject(DOCUMENT) private document: Document){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let spinner = this.document.getElementById("pre-loading-spinner")
    if (spinner) spinner.style.display= "none"
    return true;
  }

}
