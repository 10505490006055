import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { RegistrationService } from 'src/app/services/registration.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  hasConfetti = 'auth-confetti';

  @HostBinding('class.hide-footer')
  whiteLabel: boolean;

  providerTitle!: string;
  
  private subscription!: Subscription;

  constructor(private registrationService: RegistrationService) {
    this.whiteLabel = this.registrationService.whiteLabel;

    this.subscription = this.registrationService.whiteLabel$.subscribe(
      (whiteLabel: boolean) => { 
        this.whiteLabel = whiteLabel;
        if(whiteLabel) {
          this.providerTitle = this.registrationService.getProviderTitle();
        }
    });
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
