import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppPaths } from '../enums/app-paths.enum';
import { AppointmentService } from '../services/appointment.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentGuard implements CanActivate {
  constructor(
    private router: Router,
    private appointmentService: AppointmentService
  ) {}

  //Checks if the patient is in an appointment to avoid him to enter in some routes
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.appointmentService.inAppointment()) {
      return true;
    }
    return this.router.navigate([AppPaths.WARNING]);
  }

}
