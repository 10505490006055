<div class="title">
  <h1>
    {{ 'password_reset_title' | locale }}
  </h1>
</div>
<div class="card">
  <form>
    <div>
      <mat-label class="input-label">{{"Email"}}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="email"
          [formControl]="emailControl"
          [placeholder]="'login_email_placeholder' |locale">
      </mat-form-field>
    </div>
    <button
      color="primary"
      mat-flat-button
      (click)="resetPassword()"
      [disabled]="!emailControl.valid">
      {{ 'continue' | locale }}
      <mat-icon class="east">east</mat-icon>
    </button>
  </form>
</div>
