import { Component, Input } from '@angular/core';
import { Schedule } from 'src/app/models/schedule.model';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css']
})
export class SchedulesComponent {
  @Input() schedules: Schedule[] = [];
  @Input() areDoctorSchedules = false;

  constructor() { }

}
