<button
  mat-icon-button
  class="end-call"
  [ngClass]="{'floating': floatingButton}"
  (click)="hangout.emit()"
  [attr.aria-label]="'icon_button_label_end_call' | locale">
  <mat-icon svgIcon="call_phone"></mat-icon>
</button>
<button
  fileUpload
  mat-icon-button
  *ngIf="floatingButton"
  (fileToUpload)="fileToUpload.emit($event)"
  [attr.aria-label]="'icon_button_label_upload_docs' | locale">
  <mat-icon svgIcon="upload_documents"></mat-icon>
</button>
<button
  mat-icon-button
  (click)="toggleVideoCamera()"
  [attr.aria-label]="'icon_button_label_camera' | locale">
  <mat-icon [svgIcon]="videocamera ? 'videocamera' : 'videocamera_off'"></mat-icon>
</button>
<button
  mat-icon-button
  (click)="toggleMicrophone()"
  [attr.aria-label]="'icon_button_label_microphone' | locale">
  <mat-icon [svgIcon]="microphone ? 'microphone' : 'microphone_off'"></mat-icon>
</button>
