<app-stepper [numberOfSteps]="4" [currentStepIndex]="2"></app-stepper>
<app-logo *ngIf="!whiteLabel" class="bg-dark"></app-logo>
<div class="img-container">
    <img *ngIf="provider" class="providerImage" [src]="provider.header ? provider.header : provider.registrationSelectorImage">
</div>
<h1 class="validationText"> {{"registration_auth0_title" | locale }}</h1>
<div class="buttons-container">
    <div class="title-container">
        <mat-divider></mat-divider>
        <span>
            {{"social_login_enter" | locale}}
        </span>
        <mat-divider></mat-divider>
    </div>
    <button mat-raised-button class="login-button google-button" (click)="redirectToSocialLogin('google-oauth2')">
      <mat-icon svgIcon="google"></mat-icon>
        {{"social_login_google" | locale}}
    </button>
    <button mat-raised-button class="login-button outlook-button" (click)="redirectToSocialLogin('windowslive')">
        <mat-icon svgIcon="outlook"></mat-icon>
        {{"social_login_outlook" | locale}}
    </button>
    <button mat-raised-button class="login-button apple-button" (click)="redirectToSocialLogin('apple')">
        <mat-icon svgIcon="apple"></mat-icon>
        {{"social_login_apple" | locale}}
    </button>
    <button color="primary" mat-flat-button class="login-button mail-button" (click)="redirectToMailAndPassword()">
        {{"social_login_email" | locale}}
    </button>
</div>
