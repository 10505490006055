<ng-container *ngFor="let step of steps; index as index">
  <ng-container *ngIf="index < currentStepIndex">
    <span class="dot done"></span>
  </ng-container>
  <ng-container *ngIf="index === currentStepIndex">
    <p class="step-text">{{"stepper_step" | locale}} {{ index + 1 }}</p>
    <span class="rectangle"></span>
  </ng-container>
  <ng-container *ngIf="index > currentStepIndex">
    <span class="dot"></span>
  </ng-container>
</ng-container>
