<div class="mat-dialog-header">
  <h1 mat-dialog-title>
    {{ "dialog_title_warning" | locale }}
  </h1>
  <mat-icon svgIcon="heart"></mat-icon>
</div>
<div mat-dialog-content>
  <p>
    {{ "subscribe_message_1" | locale }}
    <strong>
      {{ "subscribe_message_2" | locale }}
    </strong>
  </p>
  <p>
    {{ "subscribe_message_3" | locale }}
    <a [href]="mailToLink" class="lad-email">
      {{ ladContactEmail }}
    </a>
  </p>
</div>
<div mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false">
    {{ "dialog_close" | locale }}
  </button>
  <!-- <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true">
    {{ "subscribe_button" | locale}}
    <mat-icon class="east">east</mat-icon>
  </button> -->
</div>
