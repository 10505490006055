<button
  mat-dialog-close
  mat-icon-button>
  <mat-icon>
    close
  </mat-icon>
</button>
<form [formGroup]="formGroup">
  <div mat-dialog-content>
    <label for="specialty">
      {{ "filter_by_specialty" | locale }}
    </label>
    <mat-form-field appearance="outline">
      <input
        matInput
        type="text"
        name="specialty"
        formControlName="specialty"
        [matAutocomplete]="specialtyAuto"
        [placeholder]="'filter_select' | locale">
      <mat-autocomplete
        class="custom-panel"
        #specialtyAuto="matAutocomplete"
        [displayWith]="displaySpecialtyFn.bind(this)">
        <mat-option
          [value]="specialty"
          *ngFor="let specialty of filteredSpecialties | async">
          {{ specialty | localizedName: locale }}
        </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix>
        south
      </mat-icon>
    </mat-form-field>
    <p class="error" *ngIf="specialtyControl?.hasError('invalidValue')">
      {{ 'filter_invalid_selection' | locale }}
    </p>
    <label for="doctor">
      {{ "filter_by_doctor" | locale }}
    </label>
    <mat-form-field>
      <input
        matInput
        type="text"
        name="doctor"
        formControlName="doctor"
        [matAutocomplete]="doctorAuto"
        [placeholder]="'filter_select' | locale">
      <mat-autocomplete
        class="custom-panel"
        #doctorAuto="matAutocomplete"
        [displayWith]="displayDoctorFn">
        <mat-option
          [value]="doctor"
          *ngFor="let doctor of filteredDoctors |async">
          {{ doctor.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix>
        south
      </mat-icon>
    </mat-form-field>
    <p class="error" *ngIf="doctorControl?.hasError('invalidValue')">
      {{ 'filter_invalid_selection' | locale }}
    </p>
    <label for="date">
      {{ "filter_by_date" | locale }}
    </label>
    <mat-form-field (click)="picker.open()">
      <mat-date-range-input
        matInput
        name="date"
        [max]="maxDate"
        [rangePicker]="picker">
        <input matStartDate formControlName="from" [placeholder]="'filter_select' | locale">
        <input matEndDate formControlName="to" [placeholder]="'filter_select' | locale">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon>
          south
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <label for="doctor">
      {{ "filter_by_file" | locale }}
    </label>
    <mat-form-field>
      <mat-select
        multiple
        name="file"
        color="primary"
        formControlName="file"
        [placeholder]="'filter_select' | locale">
        <mat-option
          (click)="selectionChange(option.value)"
          *ngFor="let option of fileOptions" [value]="option.value">
          {{ option.label | locale }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>
        south
      </mat-icon>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      color="accent"
      (click)="formGroup.reset()">
      {{ "filter_clear_filters" | locale }}
    </button>
    <button
      cdkFocusInitial
      mat-flat-button
      color="primary"
      (click)="submitForm()"
      [disabled]="!formGroup.valid">
      {{ "filter_apply_filters" | locale }}
      <mat-icon class="east">east</mat-icon>
    </button>
  </div>
</form>
