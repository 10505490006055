import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUpload } from 'src/app/models/file-upload.model';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css'],
})
export class FileListComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public filesUploaded: FileUpload[] = []
  ) {}

  ngOnInit(): void {}
}
