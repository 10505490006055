import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Patient } from '../models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private whiteLabelSubject = new BehaviorSubject<boolean>(false);

  set whiteLabel(hide: boolean) {
    this.whiteLabelSubject.next(hide);
  }

  get whiteLabel$(): Observable<boolean> {
    return this.whiteLabelSubject.asObservable();
  }

  get whiteLabel(): boolean {
    let hide = this.whiteLabelSubject.value;
    if(hide) return hide;

    const provider = this.getProvider();
    hide = provider ? provider.whiteLabel : null

    return this.whiteLabel = (hide != null) ? hide : false;
  }

  constructor() { }

  getProviderTitle(): string {
    const provider = this.getProvider();
    return provider?.title ?? provider?.name ?? undefined;
  }

  setProvider(provider:any){
    localStorage.setItem('provider', JSON.stringify(provider));
    this.whiteLabel = provider.whiteLabel
  }
  getProvider(){
    var provider = localStorage.getItem('provider');
    return provider ? JSON.parse(localStorage.getItem('provider')!) : "";
  }
  setPatient(patient:any){
    localStorage.setItem('patient', JSON.stringify(patient));
  }
  getPatient(){
    var patient = localStorage.getItem('patient');
    return patient ? JSON.parse(localStorage.getItem('patient')!) : "";
  }
  updatePatient(updateBody: Object){
    var patient = localStorage.getItem('patient');
    if (!patient) return;
    var parsedPatient = JSON.parse(localStorage.getItem('patient')!);
    parsedPatient = {... parsedPatient, ...updateBody}
    localStorage.setItem('patient', JSON.stringify(parsedPatient));
  }
  cleanData(){
    localStorage.removeItem('patient')
    localStorage.removeItem('provider')
  }
}
