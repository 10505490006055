<app-stepper [numberOfSteps]="4" [currentStepIndex]="2"></app-stepper>
<app-logo *ngIf="!whiteLabel" class="bg-dark"></app-logo>
<div class="img-container">
    <img *ngIf="provider" class="providerImage" [src]="provider.header ? provider.header : provider.registrationSelectorImage">
</div>
<p class="validationText"> {{"registration_auth0_title" | locale }}</p>
<div class="login-options">
    <form [formGroup]="registrationFormGroup" class="form-group">
        <mat-label class="input-label">{{"email" | locale }}</mat-label>
        <mat-form-field appearance="outline">
            <input matInput type="email" formControlName="email"
                placeholder="{{'login_email_placeholder' |locale }}">
        </mat-form-field>
        <mat-label class="input-label">{{ "password" | locale }}</mat-label>
        <mat-form-field appearance="outline">
            <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <ng-container *ngIf="!isAuthenticated && userExists">
            <a
                mat-button
                [disableRipple]="true"
                class="no-focus-overlay"
                [routerLink]="['../../', socialPath, passwordResetPath]">
                {{"login_reset_password" | locale}}
            </a>
            <br>
        </ng-container> 
       
        <button mat-flat-button
            [disabled]="!registrationFormGroup.valid || continueDisabled"
            (click)="login()"
            color="primary">
            <ng-container *ngIf="isAuthenticated; else signup">
                {{"continue" | locale}}
            </ng-container> 
            <ng-template #signup>
                {{"signup_button" | locale}}
            </ng-template>   
            <mat-icon svgIcon="east" class="east"></mat-icon>
        </button>
    </form>
</div>
