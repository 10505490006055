import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp, BackButtonListenerEvent } from '@capacitor/app';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
import { environment } from 'src/environments/environment';
import config from 'capacitor.config';
import { RequestService } from './request.service';
import { AppInfo } from '../models/app-info.model';

const enum AppPlatforms {
  ANDROID = 'android',
  IOS = 'ios',
}

@Injectable({
  providedIn: 'root'
})
export class CapacitorService {

  redirectUri = `${config.appId}://${window.location.hostname}/capacitor/callback`;
  currentPlatform = Capacitor.getPlatform();

  constructor(private requestService: RequestService) { }

  getAppInfo(): Promise<AppInfo>{
    return this.requestService.get(`/appInfo/${this.currentPlatform}`)
  }

  getRedirectUri(): string {
    return this.redirectUri;
  }

  initializeApp(): void {
    TextZoom.set({ value: 1.0})
    this.exitAppListenerCapacitor();
    SplashScreen.hide();

    if(Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setBackgroundColor({color: "#29293B"})
    }
  }

  private exitAppListenerCapacitor(): void {
    CapacitorApp
    .addListener('backButton', ({canGoBack}: BackButtonListenerEvent) => {
      if(canGoBack){
        window.history.back();
      } else {
        CapacitorApp.exitApp();
      }
    });
  }

  redirectToAppStore(): void {
    const { ANDROID_APP_STORE, IOS_APP_STORE } = environment;
   
    if (this.currentPlatform === AppPlatforms.ANDROID)
      window.location.href = ANDROID_APP_STORE;
    else if (this.currentPlatform === AppPlatforms.IOS)
      window.location.href = IOS_APP_STORE;
  }

  isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

}
