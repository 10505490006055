<div class="mat-dialog-header">
  <h1 mat-dialog-title>{{ 'file_list_title' | locale }}</h1>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<app-file-progress-bar
  [fileUpload]="file"
  *ngFor="let file of filesUploaded">
</app-file-progress-bar>
