import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const messageTypes = {
  PING: 'ping',
  DISCONNECT: "disconnect",
};

interface eventCb { (): void }

@Injectable({
  providedIn: 'root',
})
export class WebSocketsService {
  ws!: WebSocket | null;
  reconnectTimeout = 0;
  closeTimeout = 0;
  onConnectCallback!: eventCb | null;
  onDisconnectCallback!: eventCb | null;
  connecting = false;

  connect( onConnectCallback: eventCb | null, onDisconnectCallback : eventCb | null ) {
    const self = this;
    const token = localStorage.getItem('id_token');
    if (!token) return;

    this.ws = new WebSocket(environment.BASE_WS_URL + '?token=' + token);
    this.onConnectCallback = onConnectCallback;
    this.onDisconnectCallback = onDisconnectCallback;
    this.connecting = true;
    console.log('[WebSocketsService] Trying to connect to Web socket');

    this.closeTimeout = setTimeout(() => {
      if (self.ws?.readyState == WebSocket.CONNECTING) {
        console.log("[WebSocketsService] Closing connecting socket, too slow.");
        self.ws?.close();
        self.closeTimeout = 0;
      }
    }, 5000 );

    this.ws.onopen = function () {
      console.log('[WebSocketsService] Web socket connected!');
      if (self.onConnectCallback) self.onConnectCallback();
    };

    this.ws.onmessage = function (msg: any) {
      const jsonData = JSON.parse(msg.data);
      switch (jsonData.data.type) {
        case messageTypes.DISCONNECT:
          self.disconnect();
          break;
        default:
          break;
      }
    }.bind(this);

    this.ws.onclose = function (e: any) {
      if (e.code === 1000) return; //Manual close
      if (self.isConnecting()) {
        console.log('[WebSocketsService] Socket is closed. Reconnect will be attempted in 10 seconds.', e.reason);
        self.reconnectTimeout = setTimeout(function () {
          self.connect( self.onConnectCallback, self.onDisconnectCallback );
          self.reconnectTimeout = 0;
        }, 10000);
      }
      if (self.onDisconnectCallback) self.onDisconnectCallback();
    }.bind(this);

    this.ws.onerror = function (err: any) {
      self.ws?.close();
      self.ws = null;
      console.error('Api socket encountered error: ', err ? err.message : 'unknown', 'Closing socket');
    }.bind(this);
  }

  isConnecting() : boolean {
    return this.connecting;
  }

  disconnect() {
    this.clearCloseTimeout();
    this.clearReconnect();
    this.connecting = false;
    if (!this.ws) return;
    this.ws?.close(1000);
    this.ws = null;
  }

  clearCloseTimeout(): void {
    if (this.closeTimeout > 0) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = 0;
    }
  }

  clearReconnect(): void {
    if (this.reconnectTimeout > 0) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = 0;
    }
  }

  ping(): void {
    this.ws?.send(JSON.stringify({ type: messageTypes.PING }));
  }
}
